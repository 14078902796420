"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _masterDesignation = _interopRequireDefault(require("@/views/master-employee/master-designation"));
var _masterDepartment = _interopRequireDefault(require("@/views/master-employee/master-department"));
var _masterCategory = _interopRequireDefault(require("@/views/master-employee/master-category"));
var _masterEmployeetype = _interopRequireDefault(require("@/views/master-employee/master-employeetype"));
var _masterGrade = _interopRequireDefault(require("@/views/master-employee/master-grade"));
var _masterLevel = _interopRequireDefault(require("@/views/master-employee/master-level"));
var _masterExperience = _interopRequireDefault(require("@/views/master-employee/master-experience"));
var _masterSkill = _interopRequireDefault(require("@/views/master-employee/master-skill"));
var _masterSkillLevel = _interopRequireDefault(require("@/views/master-employee/master-skill-level"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Designation: _masterDesignation.default,
    Department: _masterDepartment.default,
    Category: _masterCategory.default,
    EmployeeType: _masterEmployeetype.default,
    Grade: _masterGrade.default,
    Level: _masterLevel.default,
    Experience: _masterExperience.default,
    Skill: _masterSkill.default,
    SkillLevel: _masterSkillLevel.default
  },
  data: function data() {
    return {
      tabPosition: 'left',
      activeName: 'first'
    };
  }
};
exports.default = _default;