"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.number.constructor.js");
var _utils = require("@/utils");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'RightPanel',
  props: {
    clickNotClose: {
      default: false,
      type: Boolean
    },
    buttonTop: {
      default: 250,
      type: Number
    }
  },
  data: function data() {
    return {
      show: false
    };
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    }
  },
  watch: {
    show: function show(value) {
      if (value && !this.clickNotClose) {
        this.addEventClick();
      }
      if (value) {
        (0, _utils.addClass)(document.body, 'showRightPanel');
      } else {
        (0, _utils.removeClass)(document.body, 'showRightPanel');
      }
    }
  },
  mounted: function mounted() {
    this.insertToBody();
  },
  beforeDestroy: function beforeDestroy() {
    var elx = this.$refs.rightPanel;
    elx.remove();
  },
  methods: {
    addEventClick: function addEventClick() {
      window.addEventListener('click', this.closeSidebar);
    },
    closeSidebar: function closeSidebar(evt) {
      var parent = evt.target.closest('.rightPanel');
      if (!parent) {
        this.show = false;
        window.removeEventListener('click', this.closeSidebar);
      }
    },
    insertToBody: function insertToBody() {
      var elx = this.$refs.rightPanel;
      var body = document.querySelector('body');
      body.insertBefore(elx, body.firstChild);
    }
  }
};
exports.default = _default;