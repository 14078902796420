var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "10px",
            "padding-left": "10px",
            "padding-top": "5px",
            "padding-right": "10px",
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "20%", float: "right" },
            attrs: {
              disabled: _vm.DisabledOnLoad,
              size: "small",
              placeholder: "Type and press enter to search",
            },
            on: {
              input: function ($event) {
                return _vm.getCount(_vm.search)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchByName()
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: {
                width: "15%",
                float: "left",
                "margin-bottom": "10px",
              },
              attrs: { size: "small", placeholder: "Please select" },
              on: {
                change: function ($event) {
                  return _vm.getSalaryList()
                },
              },
              model: {
                value: _vm.dropdown.status,
                callback: function ($$v) {
                  _vm.$set(_vm.dropdown, "status", $$v)
                },
                expression: "dropdown.status",
              },
            },
            [
              _c("el-option", { attrs: { label: "Active", value: "active" } }),
              _c("el-option", {
                attrs: { label: "In - Active", value: "inactive" },
              }),
              _c("el-option", { attrs: { label: "All", value: "all" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "default-sort": { prop: "employeeCode", order: "ascending" },
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            height: "570",
          },
        },
        [
          this.canEdit == true
            ? _c("el-table-column", {
                attrs: { align: "center", fixed: "", width: "120px" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-edit",
                            staticStyle: {
                              color: "orange",
                              "font-size": "20px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  776354464
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              align: "center",
              sortable: "",
              fixed: "",
              label: "Employee ID",
              width: "150",
              prop: "employeeCode",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Name",
              width: "200",
              sortable: "",
              prop: "name",
              fixed: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Designation",
              width: "260",
              sortable: "",
              prop: "designation",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Department",
              width: "160",
              sortable: "",
              prop: "department",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Category",
              width: "150",
              sortable: "",
              prop: "category",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Grade",
              sortable: "",
              width: "120",
              prop: "grade",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Level",
              sortable: "",
              width: "120",
              prop: "level",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Status",
              sortable: "",
              width: "150",
              prop: "status",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Salary",
              sortable: "",
              width: "100",
              prop: "salary",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Fixed Cost",
              sortable: "",
              width: "130",
              prop: "fixedCost",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Total Cost",
              sortable: "",
              width: "140",
              prop: "totalAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "Salary" }, [
                      _vm._v(_vm._s(scope.row.totalAmount)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp1.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp1, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getSalaryList()
          },
        },
      }),
      _c(
        "el-dialog",
        {
          staticStyle: { width: "90%" },
          attrs: {
            width: "50%",
            "close-on-click-modal": false,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-tabs",
            {
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "SALARY", name: "first" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { data: _vm.tableDataSalary },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "Salary",
                          width: "140",
                          prop: "salary",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Effective Date", width: "140" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        scope.row.affectedDate
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Remarks",
                          width: "160",
                          prop: "remarks",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: { align: "center", width: "155px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.$index == 0
                                    ? [
                                        _vm.canDelete == true
                                          ? _c("i", {
                                              staticClass: "el-icon-delete",
                                              staticStyle: {
                                                color: "orange",
                                                "font-size": "20px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDeleteSalary()
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _vm.canCreate == true
                              ? _c("i", {
                                  staticClass: "el-icon-circle-plus",
                                  staticStyle: {
                                    color: "orange",
                                    "font-size": "25px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCreateSalary()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "FIXED", name: "second" } },
                [
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "90%" },
                      attrs: { data: _vm.tableDataFixed },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "Fixed Cost",
                          width: "140",
                          prop: "fixedCost",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "Effective Date", width: "130" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("formatDate")(
                                        scope.row.affectedDate
                                      )
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "Remarks",
                          width: "160",
                          prop: "remarks",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: { align: "center", width: "145px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return scope.$index == 0
                                    ? [
                                        _vm.canDelete == true
                                          ? _c("i", {
                                              staticClass: "el-icon-delete",
                                              staticStyle: {
                                                color: "orange",
                                                "font-size": "20px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handleDelete()
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _vm.canCreate == true
                              ? _c("i", {
                                  staticClass: "el-icon-circle-plus",
                                  staticStyle: {
                                    color: "orange",
                                    "font-size": "25px",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCreateFixed()
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            visible: _vm.AddNewSalaryDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.AddNewSalaryDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                position: "relative",
                top: "-25px",
                color: "black",
                "font-size": "24px",
              },
            },
            [_vm._v("ADD SALARY")]
          ),
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "80%", "margin-left": "10%" },
              attrs: {
                model: _vm.salTab,
                rules: _vm.rules,
                "label-position": "left",
                "label-width": "40%",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Date of Joining" } },
                [
                  _c("el-date-picker", {
                    attrs: { format: "dd-MM-yyyy", disabled: "" },
                    model: {
                      value: _vm.tempDate,
                      callback: function ($$v) {
                        _vm.tempDate = $$v
                      },
                      expression: "tempDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Effective Date", prop: "affectedDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                    },
                    on: {
                      blur: function ($event) {
                        return _vm.checkDate(_vm.salTab.affectedDate)
                      },
                    },
                    model: {
                      value: _vm.salTab.affectedDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.salTab, "affectedDate", $$v)
                      },
                      expression: "salTab.affectedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "salary", label: "Salary" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "Salary" },
                    model: {
                      value: _vm.salTab.salary,
                      callback: function ($$v) {
                        _vm.$set(_vm.salTab, "salary", _vm._n($$v))
                      },
                      expression: "salTab.salary",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "remarks", label: "Remarks" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "Remarks" },
                    model: {
                      value: _vm.salTab.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.salTab, "remarks", $$v)
                      },
                      expression: "salTab.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.AddNewSalaryDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addSalary()
                    },
                  },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            visible: _vm.AddNewFixedDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.AddNewFixedDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                position: "relative",
                top: "-25px",
                color: "black",
                "font-size": "24px",
              },
            },
            [_vm._v("ADD FIXED")]
          ),
          _c(
            "el-form",
            {
              ref: "dataForm1",
              staticStyle: { width: "80%", "margin-left": "10%" },
              attrs: {
                model: _vm.fixedTab,
                rules: _vm.rules1,
                "label-position": "left",
                "label-width": "40%",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Date of Joining" } },
                [
                  _c("el-date-picker", {
                    attrs: { format: "dd-MM-yyyy", disabled: "" },
                    model: {
                      value: _vm.tempDate,
                      callback: function ($$v) {
                        _vm.tempDate = $$v
                      },
                      expression: "tempDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Effective Date", prop: "affectedDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.fixedTab.affectedDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.fixedTab, "affectedDate", $$v)
                      },
                      expression: "fixedTab.affectedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "FixedCost", label: "Fixed Cost" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    attrs: { placeholder: "FixedCost" },
                    model: {
                      value: _vm.fixedTab.FixedCost,
                      callback: function ($$v) {
                        _vm.$set(_vm.fixedTab, "FixedCost", _vm._n($$v))
                      },
                      expression: "fixedTab.FixedCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "remarks", label: "Remarks" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    model: {
                      value: _vm.fixedTab.remarks,
                      callback: function ($$v) {
                        _vm.$set(_vm.fixedTab, "remarks", $$v)
                      },
                      expression: "fixedTab.remarks",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.AddNewFixedDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.DisabledIs, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addFixedCost()
                    },
                  },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteFixedDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteFixedDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Confirm to Delete Fixed Cost!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteFixedDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.deleteFixed },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteSalaryDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteSalaryDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Confirm to Delete Salary!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteSalaryDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.deleteSalary },
                  on: {
                    click: function ($event) {
                      return _vm.deleteSalaryData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }