var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "parentDashboard" }, [
      _c("div", {
        staticStyle: { height: "450px", width: "400px" },
        attrs: { id: _vm.id },
      }),
      _c(
        "div",
        { staticClass: "tableDiv" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                data: _vm.tableData,
                id: "myTable",
                "element-loading-text": "Loading...",
                "element-loading-spinner": "el-icon-loading",
                height: "390",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "135",
                  label: "Employee ID",
                  sortable: "",
                  prop: "employeeCode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  label: "Employee Name",
                  sortable: "",
                  prop: "employeeName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "230",
                  label: "Project Name",
                  sortable: "",
                  prop: "projectName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "140",
                  label: "Department",
                  sortable: "",
                  prop: "department",
                },
              }),
              _c("el-table-column", {
                attrs: { align: "center", width: "115", label: "Release Date" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.releaseDate))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total>0",
              },
            ],
            attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp1.limit },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.temp1, "limit", $event)
              },
              pagination: function ($event) {
                return _vm.getResourceRelease()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "milestoneCountChart" }, [
      _c("div", {
        staticStyle: { height: "500px", width: "100%" },
        attrs: { id: _vm.id1 },
      }),
    ]),
    _c("div", { staticClass: "OrderBookChart" }, [
      _c("div", {
        staticStyle: { height: "500px", width: "100%" },
        attrs: { id: _vm.id2 },
      }),
    ]),
    _c("div", { staticClass: "MonthlySalaryList" }, [
      _c("div", {
        staticStyle: { height: "500px", width: "100%" },
        attrs: { id: _vm.id3 },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }