"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _masterPaystatus = _interopRequireDefault(require("@/views/master-salary/master-paystatus"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    PayStatus: _masterPaystatus.default
  },
  data: function data() {
    return {
      tabPosition: 'left',
      activeName: 'first'
    };
  }
};
exports.default = _default;