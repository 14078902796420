var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isFixedBilling
        ? _c(
            "div",
            {
              staticStyle: {
                "padding-bottom": "8px",
                "text-align": "right",
                "padding-top": "5px",
              },
            },
            [
              this.canCreate == true
                ? _c(
                    "el-button",
                    {
                      staticClass: "el-icon-circle-plus",
                      staticStyle: {
                        "padding-left": "10px",
                        color: "orange",
                        "font-size": "20px",
                      },
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          return _vm.handleCreate()
                        },
                      },
                    },
                    [_vm._v("Milestone")]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isFixedBilling
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "default-sort": { prop: "skill", order: "ascending" },
                "element-loading-text": "Loading...",
                "element-loading-spinner": "el-icon-loading",
                height: "531",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: props.row.estimationWithResource },
                            },
                            [
                              _c("el-table-column", {
                                attrs: { width: "158" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.requestStatus ===
                                            "Approved" &&
                                          scope.row.checkApprovedDate == false
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Complete",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Complete == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-success",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleComplete(
                                                                props.row,
                                                                scope.row,
                                                                "fixed"
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Release",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "release",
                                                      staticStyle: {
                                                        color: "orange",
                                                        padding: "5px",
                                                        "font-size": "25px",
                                                      },
                                                      attrs: {
                                                        className: "release",
                                                        iconClass: "release",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleRelease(
                                                            props.row,
                                                            scope.row,
                                                            "fixed"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          scope.row.requestStatus ===
                                            "Approved" &&
                                          scope.row.checkApprovedDate == true
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Delete",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Delete == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleApproveDelete(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            : scope.row.requestStatus ===
                                                "Rejected" ||
                                              scope.row.requestStatus ===
                                                "Completed" ||
                                              scope.row.requestStatus ===
                                                "Cancelled" ||
                                              scope.row.requestStatus ===
                                                "Deleted" ||
                                              scope.row.requestStatus ===
                                                "Released"
                                            ? void 0
                                            : scope.row.requestStatus !==
                                                "Approved" &&
                                              scope.row.requestStatus !==
                                                "Completed"
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Approve",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Approve == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-check",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleApprove(
                                                                props.row,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Cancel",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Cancel == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDelete(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Reject",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Reject == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-close",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleReject(
                                                                props.row,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Edit",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Edit == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-edit",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEdit(
                                                                props.row,
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Employee",
                                  width: "180",
                                  prop: "employeeName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "Hours(Day)",
                                  width: "65",
                                  prop: "hoursPerDay",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Request Type",
                                  width: "130",
                                  prop: "requestTypeName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "110",
                                  label: "Start Date",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.startDate
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "110",
                                  label: "End Date",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.endDate
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "110",
                                  label: "Actual End Date",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.actualEndDate
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "90",
                                  label: "Allocated Hrs",
                                  prop: "allocatedHours",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "80",
                                  label: "Burned Hrs",
                                  prop: "burnedHours",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "100",
                                  label: "Allocated Amount",
                                  prop: "allocAmt",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "85",
                                  label: "Burned Amount",
                                  prop: "burnedAmt",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "85",
                                  label: "Burned %",
                                  prop: "burnedPercentage",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "107",
                                  label: "Status",
                                  prop: "requestStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  436851605
                ),
              }),
              _vm.canEdit == true ||
              _vm.ResourceRequest == true ||
              _vm.canDelete == true
                ? _c("el-table-column", {
                    attrs: { width: "120", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm.canEdit == true
                                ? _c("i", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleUpdate(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.ResourceRequest == true
                                ? _c("i", {
                                    staticClass: "el-icon-circle-plus",
                                    staticStyle: {
                                      color: "orange",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.AddResource(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm.canDelete == true
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEstimationDelete(
                                          scope.row
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2259598533
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  width: "200",
                  sortable: "",
                  label: "Skill",
                  prop: "skill",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "140",
                  sortable: "",
                  label: "Skill Level",
                  prop: "skillLevel",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "115",
                  sortable: "",
                  align: "center",
                  label: "Unit Price",
                  prop: "unitPrice",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "80",
                  sortable: "",
                  align: "center",
                  label: "Hour",
                  prop: "estimatedHour",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "180",
                  sortable: "",
                  align: "center",
                  label: "Estimated Budget",
                  prop: "estimatedBudget",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "180",
                  sortable: "",
                  align: "center",
                  label: "Allocated Amount",
                  prop: "allocatedAmount",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "160",
                  sortable: "",
                  align: "center",
                  label: "Burned Amount",
                  prop: "burned",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.isFixedBilling
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                "default-sort": { prop: "skillName", order: "ascending" },
                "element-loading-text": "Loading...",
                "element-loading-spinner": "el-icon-loading",
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scopes) {
                        return [
                          _c(
                            "el-table",
                            {
                              staticStyle: { width: "100%" },
                              attrs: { data: scopes.row.milestone_Skill_Lists },
                            },
                            [
                              _c("el-table-column", {
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content: "Apporve Estimation",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              scope.row.isResourceAvalable ==
                                              false
                                                ? _c(
                                                    "Button",
                                                    {
                                                      staticStyle: {
                                                        "padding-left": "10px",
                                                        color: "orange",
                                                        "font-size": "10px",
                                                        border: "none",
                                                      },
                                                      attrs: { size: "small" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.skillhandleCreate(
                                                            "skillcreate",
                                                            scopes.$index,
                                                            scope.$index,
                                                            scopes.row,
                                                            scope.row
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Apporve")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { width: "158" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          scope.row.requestStatus === "Approved"
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Complete",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Complete == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-success",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleComplete(
                                                                scopes.row,
                                                                scope.row,
                                                                "monthly"
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Release",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticClass: "release",
                                                      staticStyle: {
                                                        color: "orange",
                                                        padding: "5px",
                                                        "font-size": "25px",
                                                      },
                                                      attrs: {
                                                        className: "release",
                                                        iconClass: "release",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.handleRelease(
                                                            scopes.row,
                                                            scope.row,
                                                            "monthly"
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                          scope.row.requestStatus ===
                                            "Approved" &&
                                          scope.row.checkApprovedDate == true
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Delete",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Delete == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleApproveDelete(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            : scope.row.requestStatus ===
                                                "Rejected" ||
                                              scope.row.requestStatus ===
                                                "Completed" ||
                                              scope.row.requestStatus ===
                                                "Cancelled" ||
                                              scope.row.requestStatus ===
                                                "Deleted" ||
                                              scope.row.requestStatus ===
                                                "Released"
                                            ? void 0
                                            : scope.row.requestStatus !==
                                                "Approved" &&
                                              scope.row.requestStatus !==
                                                "Completed" &&
                                              scope.row.isResourceAvalable ==
                                                true
                                            ? [
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Approve",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Approve == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-check",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleApproveskill(
                                                                scope.row,
                                                                scopes.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Cancel",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Cancel == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-delete",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleDelete(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                                _c(
                                                  "el-tooltip",
                                                  {
                                                    staticClass: "item",
                                                    attrs: {
                                                      effect: "dark",
                                                      content: "Reject",
                                                      placement: "top-start",
                                                    },
                                                  },
                                                  [
                                                    _vm.Reject == true
                                                      ? _c("i", {
                                                          staticClass:
                                                            "el-icon-close",
                                                          staticStyle: {
                                                            color: "orange",
                                                            padding: "5px",
                                                            "font-size": "20px",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleReject(
                                                                scope.row
                                                              )
                                                            },
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "180",
                                  sortable: "",
                                  label: "Skill",
                                  prop: "skill_Name",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "140",
                                  sortable: "",
                                  label: "Skill Level",
                                  prop: "skillLevelName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "115",
                                  sortable: "",
                                  align: "center",
                                  label: "Unit Price",
                                  prop: "unitPrice",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "110",
                                  label: "Start Date",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.start_Date
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "110",
                                  label: "End Date",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.end_Date
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "110",
                                  label: "Actual End Date",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("formatDate")(
                                                  scope.row.end_Date
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Employee",
                                  width: "150",
                                  prop: "employeeName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  label: "Hours(Day)",
                                  width: "65",
                                  prop: "hoursPerDay",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Request Type",
                                  width: "130",
                                  prop: "requestTypeName",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "180",
                                  sortable: "",
                                  align: "center",
                                  label: "Estimated Budget",
                                  prop: "bcY_Amount",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "90",
                                  label: "Allocated Hrs",
                                  prop: "allocatedHours",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "80",
                                  label: "Burned Hrs",
                                  prop: "burnedHours",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "100",
                                  label: "Allocated Amount",
                                  prop: "bcY_Amount",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "85",
                                  label: "Burned Amount",
                                  prop: "burnedAmt",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  align: "center",
                                  width: "85",
                                  label: "Burned %",
                                  prop: "burnedPercentage",
                                },
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  width: "107",
                                  label: "Status",
                                  prop: "requestStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  675513064
                ),
              }),
              _c("el-table-column", {
                attrs: { sortable: "", label: "MileStone", prop: "milestone" },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  align: "center",
                  label: "Estimated Budget",
                  prop: "totalEstimatedBudget",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  align: "center",
                  label: "Allocated Amount",
                  prop: "totalEstimatedBudget",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  sortable: "",
                  align: "center",
                  label: "Burned Amount",
                  prop: "totalBurnedAmt",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "New Resource Request",
            width: "45%",
            "close-on-click-modal": false,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-width": "55%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "projectId", label: "Project" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "230px" },
                    attrs: { required: "", disabled: "" },
                    model: {
                      value: _vm.temp.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "projectName", $$v)
                      },
                      expression: "temp.projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "skillId", label: "Skill" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "230px" },
                    attrs: { required: "", disabled: "" },
                    model: {
                      value: _vm.temp.skill,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "skill", $$v)
                      },
                      expression: "temp.skill",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "skillLevelId", label: "Skill Level" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "230px" },
                      attrs: {
                        filterable: "",
                        disabled: "",
                        placeholder: "Please select",
                      },
                      model: {
                        value: _vm.temp.skillLevelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "skillLevelId", $$v)
                        },
                        expression: "temp.skillLevelId",
                      },
                    },
                    _vm._l(_vm.skillLevels, function (item) {
                      return _c("el-option", {
                        key: item.skillLevelId,
                        attrs: {
                          label: item.skillLevel,
                          value: item.skillLevelId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Start & End Date", prop: "Dates" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "230px" },
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      format: "dd-MM-yyyy",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "Actual Start date",
                      "end-placeholder": "Actual End date",
                    },
                    model: {
                      value: _vm.temp.Dates,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "Dates", $$v)
                      },
                      expression: "temp.Dates",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "hoursPerDay", label: "Hours Per Day" } },
                [
                  _c("el-slider", {
                    staticStyle: { width: "230px" },
                    attrs: { "show-stops": "", min: 1, max: 8 },
                    model: {
                      value: _vm.temp.hoursPerDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "hoursPerDay", $$v)
                      },
                      expression: "temp.hoursPerDay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "requestTypeId", label: "Request Type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "230px" },
                      attrs: { placeholder: "Please select" },
                      model: {
                        value: _vm.temp.requestTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "requestTypeId", $$v)
                        },
                        expression: "temp.requestTypeId",
                      },
                    },
                    _vm._l(_vm.requesttypes, function (item) {
                      return _c("el-option", {
                        key: item.labelId,
                        attrs: { label: item.labelName, value: item.labelId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "requesterComment",
                    label: "Requester Comment(Employee Suggestion)",
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "230px" },
                    model: {
                      value: _vm.temp.requesterComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "requesterComment", $$v)
                      },
                      expression: "temp.requesterComment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.createData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Approve Resource Request",
            "close-on-click-modal": false,
            width: "40%",
            visible: _vm.approveDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.approveDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm1",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules2,
                model: _vm.temp,
                "label-width": "50%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "requesterComment",
                    label: "Requester Comment",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.temp.requesterComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "requesterComment", $$v)
                      },
                      expression: "temp.requesterComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.ExtraEffort()
                        },
                      },
                      model: {
                        value: _vm.temp.check,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "check", $$v)
                        },
                        expression: "temp.check",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        attrs: { label: "Extra Effort", name: "type" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "employeeId", label: "Employee" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      on: {
                        change: function ($event) {
                          return _vm.EmployeeSalary(_vm.temp.employeeId)
                        },
                      },
                      model: {
                        value: _vm.temp.employeeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "employeeId", $$v)
                        },
                        expression: "temp.employeeId",
                      },
                    },
                    _vm._l(_vm.employee, function (item) {
                      return _c("el-option", {
                        key: item.employeeId,
                        attrs: {
                          label: item.employeeName,
                          value: item.employeeId,
                        },
                      })
                    }),
                    1
                  ),
                  _c("div", {
                    staticClass: "el-form-item__error",
                    attrs: { id: "hour" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Cost Per Hour ",
                    prop: "perHourEmployeeCost",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: this.temp.perHourEmployeeCost,
                      callback: function ($$v) {
                        _vm.$set(this.temp, "perHourEmployeeCost", $$v)
                      },
                      expression: "this.temp.perHourEmployeeCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "hoursPerDay", label: "Hours Per Day" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.hoursPerDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "hoursPerDay", _vm._n($$v))
                      },
                      expression: "temp.hoursPerDay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "approverComment", label: "Approver Comment" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.approverComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "approverComment", $$v)
                      },
                      expression: "temp.approverComment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.approveDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.DisabledIs, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.ApproveData()
                    },
                  },
                },
                [_vm._v("Approve")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            title: "Reject Resource Request",
            visible: _vm.rejectDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.rejectDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm2",
              staticStyle: { width: "80%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules3,
                model: _vm.temp,
                "label-width": "50%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "requesterComment",
                    label: "Requester Comment",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.temp.requesterComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "requesterComment", $$v)
                      },
                      expression: "temp.requesterComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "Reason For Rejecting" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "reason", $$v)
                      },
                      expression: "temp.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.rejectDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.IsDisable },
                  on: {
                    click: function ($event) {
                      return _vm.RejectData()
                    },
                  },
                },
                [_vm._v("Reject")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            title: "Edit Resource Request",
            visible: _vm.editDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm4",
              staticStyle: { width: "80%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules5,
                model: _vm.temp,
                "label-width": "50%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Start Date", prop: "startDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "startDate", $$v)
                      },
                      expression: "temp.startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date", prop: "endDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "endDate", $$v)
                      },
                      expression: "temp.endDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "hoursPerDay", label: "Hours Per Day" } },
                [
                  _c("el-slider", {
                    staticStyle: { width: "220px" },
                    attrs: { "show-stops": "", min: 1, max: 8 },
                    model: {
                      value: _vm.temp.hoursPerDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "hoursPerDay", $$v)
                      },
                      expression: "temp.hoursPerDay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "requestTypeId", label: "Request Type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      staticStyle: { width: "225px" },
                      attrs: { placeholder: "Please select" },
                      model: {
                        value: _vm.temp.requestTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "requestTypeId", $$v)
                        },
                        expression: "temp.requestTypeId",
                      },
                    },
                    _vm._l(_vm.requesttypes, function (item) {
                      return _c("el-option", {
                        key: item.labelId,
                        attrs: { label: item.labelName, value: item.labelId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.EditDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.EditData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35%",
            "close-on-click-modal": false,
            title: "Complete Resource Request",
            visible: _vm.completeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.completeDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm3",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules1,
                model: _vm.temp1,
                "label-width": "45%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Start Date", prop: "actualStartDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.temp1.actualStartDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualStartDate", $$v)
                      },
                      expression: "temp1.actualStartDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date", prop: "actualEndDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp1.actualEndDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualEndDate", $$v)
                      },
                      expression: "temp1.actualEndDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "Reason" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    model: {
                      value: _vm.temp1.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "reason", $$v)
                      },
                      expression: "temp1.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.completeDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.CompleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.CompleteData()
                    },
                  },
                },
                [_vm._v("Complete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35%",
            "close-on-click-modal": false,
            title: "Release Resource Request",
            visible: _vm.releaseDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.releaseDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm5",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules1,
                model: _vm.temp1,
                "label-width": "45%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Start Date", prop: "actualStartDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.temp1.actualStartDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualStartDate", $$v)
                      },
                      expression: "temp1.actualStartDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date", prop: "actualEndDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp1.actualEndDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualEndDate", $$v)
                      },
                      expression: "temp1.actualEndDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "Reason" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "220px" },
                    model: {
                      value: _vm.temp1.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "reason", $$v)
                      },
                      expression: "temp1.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.releaseDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.ReleaseDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.ReleaseData()
                    },
                  },
                },
                [_vm._v("Release")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteResourceRequestDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteResourceRequestDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the Resource Request")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteResourceRequestDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.DeleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteEstimationDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteEstimationDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the Estimation")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteEstimationDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.DeleteEstimationDisabled,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteEstimatedData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteApprovedDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteApprovedDialog = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("Please confirm to delete the Approved Resource Request"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteApprovedDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.DeleteApprovedDisabled,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.DeleteApprovedData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "45%",
            title: _vm.textMap[_vm.dialogStatus],
            "close-on-click-modal": false,
            visible: _vm.dialogEstimationFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogEstimationFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataFormCreate",
              staticStyle: { width: "70%", "margin-left": "10%" },
              attrs: {
                rules: _vm.rules4,
                model: _vm.tempCreateEstimation,
                "label-width": "55%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "projectId", label: "Project Name" } },
                [
                  _c("el-input", {
                    attrs: {
                      "controls-position": "right",
                      required: "",
                      disabled: "",
                    },
                    model: {
                      value: this.Name,
                      callback: function ($$v) {
                        _vm.$set(this, "Name", $$v)
                      },
                      expression: "this.Name",
                    },
                  }),
                ],
                1
              ),
              _vm.dialogStatus === "create"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "skill Name", prop: "skillId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            filterable: "",
                            placeholder: "Please select",
                          },
                          model: {
                            value: _vm.tempCreateEstimation.skillId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempCreateEstimation, "skillId", $$v)
                            },
                            expression: "tempCreateEstimation.skillId",
                          },
                        },
                        _vm._l(_vm.skills, function (item) {
                          return _c("el-option", {
                            key: item.skillId,
                            attrs: { label: item.skill, value: item.skillId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "skill Name", prop: "skillId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            filterable: "",
                            disabled: "",
                            placeholder: "Please select",
                          },
                          model: {
                            value: _vm.tempCreateEstimation.skillId,
                            callback: function ($$v) {
                              _vm.$set(_vm.tempCreateEstimation, "skillId", $$v)
                            },
                            expression: "tempCreateEstimation.skillId",
                          },
                        },
                        _vm._l(_vm.skills, function (item) {
                          return _c("el-option", {
                            key: item.skillId,
                            attrs: { label: item.skill, value: item.skillId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { prop: "skillLevelId", label: "Skill Level" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.tempCreateEstimation.skillLevelId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.tempCreateEstimation,
                            "skillLevelId",
                            $$v
                          )
                        },
                        expression: "tempCreateEstimation.skillLevelId",
                      },
                    },
                    _vm._l(_vm.skillLevels, function (item) {
                      return _c("el-option", {
                        key: item.skillLevelId,
                        attrs: {
                          label: item.skillLevel,
                          value: item.skillLevelId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    { attrs: { prop: "skillLevelId", label: "Skill Level" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            filterable: "",
                            disabled: "",
                            placeholder: "Please select",
                          },
                          model: {
                            value: _vm.tempCreateEstimation.skillLevelId,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.tempCreateEstimation,
                                "skillLevelId",
                                $$v
                              )
                            },
                            expression: "tempCreateEstimation.skillLevelId",
                          },
                        },
                        _vm._l(_vm.skillLevels, function (item) {
                          return _c("el-option", {
                            key: item.skillLevelId,
                            attrs: {
                              label: item.skillLevel,
                              value: item.skillLevelId,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { prop: "unitPrice", label: "Unit Price Per Hour" } },
                [
                  _c("el-input", {
                    on: {
                      change: function ($event) {
                        return _vm.calhour()
                      },
                    },
                    model: {
                      value: _vm.tempCreateEstimation.unitPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tempCreateEstimation,
                          "unitPrice",
                          _vm._n($$v)
                        )
                      },
                      expression: "tempCreateEstimation.unitPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "estimatedHour", label: "Estimated Hour" } },
                [
                  _c("el-input", {
                    on: {
                      change: function ($event) {
                        return _vm.calhour()
                      },
                    },
                    model: {
                      value: _vm.tempCreateEstimation.estimatedHour,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tempCreateEstimation,
                          "estimatedHour",
                          _vm._n($$v)
                        )
                      },
                      expression: "tempCreateEstimation.estimatedHour",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "Estimated Budget", prop: "estimatedBudget" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      "controls-position": "right",
                      disabled: "",
                      placeholder: "Project Cost",
                    },
                    model: {
                      value: _vm.tempCreateEstimation.estimatedBudget,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tempCreateEstimation,
                          "estimatedBudget",
                          $$v
                        )
                      },
                      expression: "tempCreateEstimation.estimatedBudget",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogEstimationFormVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.CreateEstimationDisabled,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createEstimationData()
                        : _vm.updateEstimationData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "45%",
            title: _vm.textMap[_vm.dialogStatus],
            "close-on-click-modal": false,
            visible: _vm.dialogSkillEstimationFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSkillEstimationFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataFormCreate",
              staticStyle: { width: "70%", "margin-left": "10%" },
              attrs: {
                rules: _vm.rules4,
                model: _vm.tempCreateSkillEstimation,
                "label-width": "55%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "projectId", label: "Project Name" } },
                [
                  _c("el-input", {
                    attrs: {
                      "controls-position": "right",
                      required: "",
                      disabled: "",
                    },
                    model: {
                      value: this.Name,
                      callback: function ($$v) {
                        _vm.$set(this, "Name", $$v)
                      },
                      expression: "this.Name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Milestone Name", prop: "milestoneId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.tempCreateSkillEstimation.milestoneId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.tempCreateSkillEstimation,
                            "milestoneId",
                            $$v
                          )
                        },
                        expression: "tempCreateSkillEstimation.milestoneId",
                      },
                    },
                    _vm._l(_vm.projectMilestones, function (item) {
                      return _c("el-option", {
                        key: item.milestoneId,
                        attrs: {
                          label: item.milestone,
                          value: item.milestoneId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "skill Name", prop: "skillId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.tempCreateSkillEstimation.skillId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.tempCreateSkillEstimation,
                            "skillId",
                            $$v
                          )
                        },
                        expression: "tempCreateSkillEstimation.skillId",
                      },
                    },
                    _vm._l(_vm.skills, function (item) {
                      return _c("el-option", {
                        key: item.skillId,
                        attrs: { label: item.skill, value: item.skillId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "skillLevelId", label: "Skill Level" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.tempCreateSkillEstimation.skillLevelId,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.tempCreateSkillEstimation,
                            "skillLevelId",
                            $$v
                          )
                        },
                        expression: "tempCreateSkillEstimation.skillLevelId",
                      },
                    },
                    _vm._l(_vm.skillLevels, function (item) {
                      return _c("el-option", {
                        key: item.skillLevelId,
                        attrs: {
                          label: item.skillLevel,
                          value: item.skillLevelId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "unitPrice", label: "Unit Price Per Hour" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.tempCreateSkillEstimation.unitPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tempCreateSkillEstimation,
                          "unitPrice",
                          _vm._n($$v)
                        )
                      },
                      expression: "tempCreateSkillEstimation.unitPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "estimatedHour", label: "Estimated Hour" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.tempCreateSkillEstimation.estimatedHour,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tempCreateSkillEstimation,
                          "estimatedHour",
                          _vm._n($$v)
                        )
                      },
                      expression: "tempCreateSkillEstimation.estimatedHour",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "Estimated Budget", prop: "estimatedBudget" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      "controls-position": "right",
                      disabled: "",
                      placeholder: "Project Cost",
                    },
                    model: {
                      value: _vm.tempCreateSkillEstimation.estimatedBudget,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.tempCreateSkillEstimation,
                          "estimatedBudget",
                          $$v
                        )
                      },
                      expression: "tempCreateSkillEstimation.estimatedBudget",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogSkillEstimationFormVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: _vm.CreateSkillEstimationDisabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.createSkillEstimationData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }