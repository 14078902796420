var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticStyle: {
          "padding-bottom": "10px",
          "padding-left": "10px",
          "padding-top": "10px",
          "padding-right": "10px",
        },
      }),
      _c("div", { staticClass: "parentMaster" }, [
        _c(
          "div",
          { staticStyle: { width: "30%" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c("span", [_vm._v("Role")]),
                          this.canCreate == true
                            ? _c(
                                "el-button",
                                {
                                  staticClass: "buttonRole",
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                  },
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCreate()
                                    },
                                  },
                                },
                                [_vm._v("Add Role")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "el-table",
                            {
                              directives: [
                                {
                                  name: "loading",
                                  rawName: "v-loading",
                                  value: _vm.loading,
                                  expression: "loading",
                                },
                              ],
                              staticStyle: { width: "100%" },
                              attrs: {
                                data: _vm.tableData,
                                "default-sort": {
                                  prop: "roleName",
                                  order: "ascending",
                                },
                                "element-loading-text": "Loading...",
                                "element-loading-spinner": "el-icon-loading",
                              },
                              on: { "current-change": _vm.handleCurrentChange },
                            },
                            [
                              this.canEdit == true || this.canDelete == true
                                ? _c("el-table-column", {
                                    attrs: {
                                      align: "center",
                                      width: "150px",
                                      fixed: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm.canEdit == true
                                                ? _c("i", {
                                                    staticClass: "el-icon-edit",
                                                    staticStyle: {
                                                      "padding-right": "20px",
                                                      color: "orange",
                                                      "font-size": "20px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleUpdate(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _vm.canDelete == true
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-delete",
                                                    staticStyle: {
                                                      "padding-right": "20px",
                                                      color: "orange",
                                                      "font-size": "20px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.handleDelete(
                                                          scope.row
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2234267804
                                    ),
                                  })
                                : _vm._e(),
                              _c("el-table-column", {
                                attrs: {
                                  sortable: "",
                                  label: "Role Name",
                                  prop: "roleName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticStyle: { width: "60%", "margin-left": "20px" },
            attrs: {
              "element-loading-text": "Loading...",
              "element-loading-spinner": "el-icon-loading",
            },
          },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c(
                      "el-card",
                      {
                        staticClass: "box-card1",
                        staticStyle: { height: "65px" },
                      },
                      [
                        _c("span", [_vm._v("Role : ")]),
                        _c("span", [_vm._v(_vm._s(this.temp.roleName))]),
                        this.canEditScreenRole == true
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  "margin-top": "-8px",
                                  float: "right",
                                  "background-color": "orange",
                                  color: "#fff",
                                },
                                attrs: { disabled: _vm.DisabledButton },
                                on: {
                                  click: function ($event) {
                                    return _vm.handlePermissionCreate()
                                  },
                                },
                              },
                              [_vm._v("Save")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Dashboard")])]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "padding-bottom": "20px" } },
                        [
                          _c("el-checkbox", {
                            staticClass: "clearfix",
                            attrs: { disabled: "", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(44)
                              },
                            },
                            model: {
                              value: _vm.permissions["44"],
                              callback: function ($$v) {
                                _vm.$set(_vm.permissions, "44", $$v)
                              },
                              expression: "permissions['44']",
                            },
                          }),
                          _c("label", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.name["44"])),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", {
                            attrs: { label: "Create", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(44)
                              },
                            },
                            model: {
                              value: _vm.create["44"],
                              callback: function ($$v) {
                                _vm.$set(_vm.create, "44", $$v)
                              },
                              expression: "create['44']",
                            },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "Read", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(44)
                              },
                            },
                            model: {
                              value: _vm.read["44"],
                              callback: function ($$v) {
                                _vm.$set(_vm.read, "44", $$v)
                              },
                              expression: "read['44']",
                            },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "Update", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(44)
                              },
                            },
                            model: {
                              value: _vm.update["44"],
                              callback: function ($$v) {
                                _vm.$set(_vm.update, "44", $$v)
                              },
                              expression: "update['44']",
                            },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "Delete", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(44)
                              },
                            },
                            model: {
                              value: _vm.Delete["44"],
                              callback: function ($$v) {
                                _vm.$set(_vm.Delete, "44", $$v)
                              },
                              expression: "Delete['44']",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Employee")])]
                      ),
                      _c(
                        "div",
                        { staticStyle: { "padding-bottom": "20px" } },
                        [
                          _c("el-checkbox", {
                            staticClass: "clearfix",
                            attrs: { disabled: "", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(6)
                              },
                            },
                            model: {
                              value: _vm.permissions["6"],
                              callback: function ($$v) {
                                _vm.$set(_vm.permissions, "6", $$v)
                              },
                              expression: "permissions['6']",
                            },
                          }),
                          _c("label", { staticClass: "label" }, [
                            _vm._v(_vm._s(_vm.name["6"])),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("el-checkbox", {
                            attrs: { label: "Create", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(6)
                              },
                            },
                            model: {
                              value: _vm.create["6"],
                              callback: function ($$v) {
                                _vm.$set(_vm.create, "6", $$v)
                              },
                              expression: "create['6']",
                            },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "Read", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(6)
                              },
                            },
                            model: {
                              value: _vm.read["6"],
                              callback: function ($$v) {
                                _vm.$set(_vm.read, "6", $$v)
                              },
                              expression: "read['6']",
                            },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "Update", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(6)
                              },
                            },
                            model: {
                              value: _vm.update["6"],
                              callback: function ($$v) {
                                _vm.$set(_vm.update, "6", $$v)
                              },
                              expression: "update['6']",
                            },
                          }),
                          _c("el-checkbox", {
                            attrs: { label: "Delete", name: "type" },
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckBox(6)
                              },
                            },
                            model: {
                              value: _vm.Delete["6"],
                              callback: function ($$v) {
                                _vm.$set(_vm.Delete, "6", $$v)
                              },
                              expression: "Delete['6']",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Salary")])]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(9)
                                },
                              },
                              model: {
                                value: _vm.permissions["9"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "9", $$v)
                                },
                                expression: "permissions['9']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["9"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(9)
                                },
                              },
                              model: {
                                value: _vm.create["9"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "9", $$v)
                                },
                                expression: "create['9']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(9)
                                },
                              },
                              model: {
                                value: _vm.read["9"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "9", $$v)
                                },
                                expression: "read['9']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(9)
                                },
                              },
                              model: {
                                value: _vm.update["9"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "9", $$v)
                                },
                                expression: "update['9']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(9)
                                },
                              },
                              model: {
                                value: _vm.Delete["9"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "9", $$v)
                                },
                                expression: "Delete['9']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(10)
                                },
                              },
                              model: {
                                value: _vm.permissions["10"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "10", $$v)
                                },
                                expression: "permissions['10']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["10"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(10)
                                },
                              },
                              model: {
                                value: _vm.create["10"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "10", $$v)
                                },
                                expression: "create['10']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(10)
                                },
                              },
                              model: {
                                value: _vm.read["10"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "10", $$v)
                                },
                                expression: "read['10']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(10)
                                },
                              },
                              model: {
                                value: _vm.update["10"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "10", $$v)
                                },
                                expression: "update['10']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(10)
                                },
                              },
                              model: {
                                value: _vm.Delete["10"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "10", $$v)
                                },
                                expression: "Delete['10']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(42)
                                },
                              },
                              model: {
                                value: _vm.permissions["42"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "42", $$v)
                                },
                                expression: "permissions['42']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["42"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(42)
                                },
                              },
                              model: {
                                value: _vm.create["42"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "42", $$v)
                                },
                                expression: "create['42']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(42)
                                },
                              },
                              model: {
                                value: _vm.read["42"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "42", $$v)
                                },
                                expression: "read['42']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(42)
                                },
                              },
                              model: {
                                value: _vm.update["42"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "42", $$v)
                                },
                                expression: "update['42']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(42)
                                },
                              },
                              model: {
                                value: _vm.Delete["42"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "42", $$v)
                                },
                                expression: "Delete['42']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(53)
                                },
                              },
                              model: {
                                value: _vm.permissions["53"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "53", $$v)
                                },
                                expression: "permissions['53']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["53"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(53)
                                },
                              },
                              model: {
                                value: _vm.create["53"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "53", $$v)
                                },
                                expression: "create['53']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(53)
                                },
                              },
                              model: {
                                value: _vm.read["53"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "53", $$v)
                                },
                                expression: "read['53']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(53)
                                },
                              },
                              model: {
                                value: _vm.update["53"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "53", $$v)
                                },
                                expression: "update['53']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(53)
                                },
                              },
                              model: {
                                value: _vm.Delete["53"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "53", $$v)
                                },
                                expression: "Delete['53']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Milestone")])]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(45)
                                },
                              },
                              model: {
                                value: _vm.permissions["45"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "45", $$v)
                                },
                                expression: "permissions['45']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["45"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(45)
                                },
                              },
                              model: {
                                value: _vm.create["45"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "45", $$v)
                                },
                                expression: "create['45']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(45)
                                },
                              },
                              model: {
                                value: _vm.read["45"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "45", $$v)
                                },
                                expression: "read['45']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(45)
                                },
                              },
                              model: {
                                value: _vm.update["45"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "45", $$v)
                                },
                                expression: "update['45']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(45)
                                },
                              },
                              model: {
                                value: _vm.Delete["45"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "45", $$v)
                                },
                                expression: "Delete['45']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(46)
                                },
                              },
                              model: {
                                value: _vm.permissions["46"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "46", $$v)
                                },
                                expression: "permissions['46']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["46"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(46)
                                },
                              },
                              model: {
                                value: _vm.create["46"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "46", $$v)
                                },
                                expression: "create['46']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(46)
                                },
                              },
                              model: {
                                value: _vm.read["46"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "46", $$v)
                                },
                                expression: "read['46']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(46)
                                },
                              },
                              model: {
                                value: _vm.update["46"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "46", $$v)
                                },
                                expression: "update['46']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(46)
                                },
                              },
                              model: {
                                value: _vm.Delete["46"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "46", $$v)
                                },
                                expression: "Delete['46']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(48)
                                },
                              },
                              model: {
                                value: _vm.permissions["48"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "48", $$v)
                                },
                                expression: "permissions['48']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["48"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(48)
                                },
                              },
                              model: {
                                value: _vm.create["48"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "48", $$v)
                                },
                                expression: "create['48']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(48)
                                },
                              },
                              model: {
                                value: _vm.read["48"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "48", $$v)
                                },
                                expression: "read['48']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(48)
                                },
                              },
                              model: {
                                value: _vm.update["48"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "48", $$v)
                                },
                                expression: "update['48']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(48)
                                },
                              },
                              model: {
                                value: _vm.Delete["48"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "48", $$v)
                                },
                                expression: "Delete['48']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(47)
                                },
                              },
                              model: {
                                value: _vm.permissions["47"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "47", $$v)
                                },
                                expression: "permissions['47']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["47"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(47)
                                },
                              },
                              model: {
                                value: _vm.create["47"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "47", $$v)
                                },
                                expression: "create['47']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(47)
                                },
                              },
                              model: {
                                value: _vm.read["47"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "47", $$v)
                                },
                                expression: "read['47']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(47)
                                },
                              },
                              model: {
                                value: _vm.update["47"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "47", $$v)
                                },
                                expression: "update['47']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(47)
                                },
                              },
                              model: {
                                value: _vm.Delete["47"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "47", $$v)
                                },
                                expression: "Delete['47']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(54)
                                },
                              },
                              model: {
                                value: _vm.permissions["54"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "54", $$v)
                                },
                                expression: "permissions['54']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["54"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(54)
                                },
                              },
                              model: {
                                value: _vm.create["54"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "54", $$v)
                                },
                                expression: "create['54']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(54)
                                },
                              },
                              model: {
                                value: _vm.read["54"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "54", $$v)
                                },
                                expression: "read['54']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(54)
                                },
                              },
                              model: {
                                value: _vm.update["54"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "54", $$v)
                                },
                                expression: "update['54']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(54)
                                },
                              },
                              model: {
                                value: _vm.Delete["54"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "54", $$v)
                                },
                                expression: "Delete['54']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(49)
                                },
                              },
                              model: {
                                value: _vm.permissions["49"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "49", $$v)
                                },
                                expression: "permissions['49']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["49"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(49)
                                },
                              },
                              model: {
                                value: _vm.create["49"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "49", $$v)
                                },
                                expression: "create['49']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(49)
                                },
                              },
                              model: {
                                value: _vm.read["49"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "49", $$v)
                                },
                                expression: "read['49']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(49)
                                },
                              },
                              model: {
                                value: _vm.update["49"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "49", $$v)
                                },
                                expression: "update['49']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(49)
                                },
                              },
                              model: {
                                value: _vm.Delete["49"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "49", $$v)
                                },
                                expression: "Delete['49']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Project")])]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(13)
                                },
                              },
                              model: {
                                value: _vm.permissions["13"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "13", $$v)
                                },
                                expression: "permissions['13']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["13"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(13)
                                },
                              },
                              model: {
                                value: _vm.create["13"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "13", $$v)
                                },
                                expression: "create['13']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(13)
                                },
                              },
                              model: {
                                value: _vm.read["13"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "13", $$v)
                                },
                                expression: "read['13']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(13)
                                },
                              },
                              model: {
                                value: _vm.update["13"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "13", $$v)
                                },
                                expression: "update['13']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(13)
                                },
                              },
                              model: {
                                value: _vm.Delete["13"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "13", $$v)
                                },
                                expression: "Delete['13']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(41)
                                },
                              },
                              model: {
                                value: _vm.permissions["41"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "41", $$v)
                                },
                                expression: "permissions['41']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["41"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(41)
                                },
                              },
                              model: {
                                value: _vm.create["41"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "41", $$v)
                                },
                                expression: "create['41']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(41)
                                },
                              },
                              model: {
                                value: _vm.read["41"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "41", $$v)
                                },
                                expression: "read['41']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(41)
                                },
                              },
                              model: {
                                value: _vm.update["41"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "41", $$v)
                                },
                                expression: "update['41']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(41)
                                },
                              },
                              model: {
                                value: _vm.Delete["41"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "41", $$v)
                                },
                                expression: "Delete['41']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(37)
                                },
                              },
                              model: {
                                value: _vm.permissions["37"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "37", $$v)
                                },
                                expression: "permissions['37']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["37"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(37)
                                },
                              },
                              model: {
                                value: _vm.create["37"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "37", $$v)
                                },
                                expression: "create['37']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(37)
                                },
                              },
                              model: {
                                value: _vm.read["37"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "37", $$v)
                                },
                                expression: "read['37']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(37)
                                },
                              },
                              model: {
                                value: _vm.update["37"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "37", $$v)
                                },
                                expression: "update['37']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(37)
                                },
                              },
                              model: {
                                value: _vm.Delete["37"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "37", $$v)
                                },
                                expression: "Delete['37']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(38)
                                },
                              },
                              model: {
                                value: _vm.permissions["38"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "38", $$v)
                                },
                                expression: "permissions['38']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["38"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(38)
                                },
                              },
                              model: {
                                value: _vm.create["38"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "38", $$v)
                                },
                                expression: "create['38']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(38)
                                },
                              },
                              model: {
                                value: _vm.read["38"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "38", $$v)
                                },
                                expression: "read['38']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(38)
                                },
                              },
                              model: {
                                value: _vm.update["38"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "38", $$v)
                                },
                                expression: "update['38']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(38)
                                },
                              },
                              model: {
                                value: _vm.Delete["38"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "38", $$v)
                                },
                                expression: "Delete['38']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(39)
                                },
                              },
                              model: {
                                value: _vm.permissions["39"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "39", $$v)
                                },
                                expression: "permissions['39']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["39"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(39)
                                },
                              },
                              model: {
                                value: _vm.create["39"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "39", $$v)
                                },
                                expression: "create['39']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(39)
                                },
                              },
                              model: {
                                value: _vm.read["39"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "39", $$v)
                                },
                                expression: "read['39']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(39)
                                },
                              },
                              model: {
                                value: _vm.update["39"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "39", $$v)
                                },
                                expression: "update['39']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(39)
                                },
                              },
                              model: {
                                value: _vm.Delete["39"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "39", $$v)
                                },
                                expression: "Delete['39']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(40)
                                },
                              },
                              model: {
                                value: _vm.permissions["40"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "40", $$v)
                                },
                                expression: "permissions['40']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["40"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(40)
                                },
                              },
                              model: {
                                value: _vm.create["40"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "40", $$v)
                                },
                                expression: "create['40']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(40)
                                },
                              },
                              model: {
                                value: _vm.read["40"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "40", $$v)
                                },
                                expression: "read['40']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(40)
                                },
                              },
                              model: {
                                value: _vm.update["40"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "40", $$v)
                                },
                                expression: "update['40']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(40)
                                },
                              },
                              model: {
                                value: _vm.Delete["40"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "40", $$v)
                                },
                                expression: "Delete['40']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(50)
                                },
                              },
                              model: {
                                value: _vm.permissions["50"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "50", $$v)
                                },
                                expression: "permissions['50']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["50"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(50)
                                },
                              },
                              model: {
                                value: _vm.create["50"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "50", $$v)
                                },
                                expression: "create['50']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(50)
                                },
                              },
                              model: {
                                value: _vm.read["50"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "50", $$v)
                                },
                                expression: "read['50']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(50)
                                },
                              },
                              model: {
                                value: _vm.update["50"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "50", $$v)
                                },
                                expression: "update['50']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(50)
                                },
                              },
                              model: {
                                value: _vm.Delete["50"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "50", $$v)
                                },
                                expression: "Delete['50']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Resource")])]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(14)
                                },
                              },
                              model: {
                                value: _vm.permissions["14"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "14", $$v)
                                },
                                expression: "permissions['14']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["14"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(14)
                                },
                              },
                              model: {
                                value: _vm.create["14"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "14", $$v)
                                },
                                expression: "create['14']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(14)
                                },
                              },
                              model: {
                                value: _vm.read["14"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "14", $$v)
                                },
                                expression: "read['14']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(14)
                                },
                              },
                              model: {
                                value: _vm.update["14"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "14", $$v)
                                },
                                expression: "update['14']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(14)
                                },
                              },
                              model: {
                                value: _vm.Delete["14"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "14", $$v)
                                },
                                expression: "Delete['14']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(51)
                                },
                              },
                              model: {
                                value: _vm.permissions["51"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "51", $$v)
                                },
                                expression: "permissions['51']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["51"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(51)
                                },
                              },
                              model: {
                                value: _vm.create["51"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "51", $$v)
                                },
                                expression: "create['51']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(51)
                                },
                              },
                              model: {
                                value: _vm.read["51"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "51", $$v)
                                },
                                expression: "read['51']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(51)
                                },
                              },
                              model: {
                                value: _vm.update["51"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "51", $$v)
                                },
                                expression: "update['51']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(51)
                                },
                              },
                              model: {
                                value: _vm.Delete["51"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "51", $$v)
                                },
                                expression: "Delete['51']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(15)
                                },
                              },
                              model: {
                                value: _vm.permissions["15"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "15", $$v)
                                },
                                expression: "permissions['15']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["15"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(15)
                                },
                              },
                              model: {
                                value: _vm.create["15"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "15", $$v)
                                },
                                expression: "create['15']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(15)
                                },
                              },
                              model: {
                                value: _vm.read["15"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "15", $$v)
                                },
                                expression: "read['15']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(15)
                                },
                              },
                              model: {
                                value: _vm.update["15"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "15", $$v)
                                },
                                expression: "update['15']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(15)
                                },
                              },
                              model: {
                                value: _vm.Delete["15"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "15", $$v)
                                },
                                expression: "Delete['15']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(43)
                                },
                              },
                              model: {
                                value: _vm.permissions["43"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "43", $$v)
                                },
                                expression: "permissions['43']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["43"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(43)
                                },
                              },
                              model: {
                                value: _vm.create["43"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "43", $$v)
                                },
                                expression: "create['43']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(43)
                                },
                              },
                              model: {
                                value: _vm.read["43"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "43", $$v)
                                },
                                expression: "read['43']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(43)
                                },
                              },
                              model: {
                                value: _vm.update["43"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "43", $$v)
                                },
                                expression: "update['43']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(43)
                                },
                              },
                              model: {
                                value: _vm.Delete["43"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "43", $$v)
                                },
                                expression: "Delete['43']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(16)
                                },
                              },
                              model: {
                                value: _vm.permissions["16"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "16", $$v)
                                },
                                expression: "permissions['16']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["16"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(16)
                                },
                              },
                              model: {
                                value: _vm.create["16"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "16", $$v)
                                },
                                expression: "create['16']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(16)
                                },
                              },
                              model: {
                                value: _vm.read["16"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "16", $$v)
                                },
                                expression: "read['16']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(16)
                                },
                              },
                              model: {
                                value: _vm.update["16"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "16", $$v)
                                },
                                expression: "update['16']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(16)
                                },
                              },
                              model: {
                                value: _vm.Delete["16"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "16", $$v)
                                },
                                expression: "Delete['16']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Report")])]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(17)
                                },
                              },
                              model: {
                                value: _vm.permissions["17"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "17", $$v)
                                },
                                expression: "permissions['17']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["17"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(17)
                                },
                              },
                              model: {
                                value: _vm.create["17"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "17", $$v)
                                },
                                expression: "create['17']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(17)
                                },
                              },
                              model: {
                                value: _vm.read["17"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "17", $$v)
                                },
                                expression: "read['17']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(17)
                                },
                              },
                              model: {
                                value: _vm.update["17"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "17", $$v)
                                },
                                expression: "update['17']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(17)
                                },
                              },
                              model: {
                                value: _vm.Delete["17"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "17", $$v)
                                },
                                expression: "Delete['17']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(18)
                                },
                              },
                              model: {
                                value: _vm.permissions["18"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "18", $$v)
                                },
                                expression: "permissions['18']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["18"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(18)
                                },
                              },
                              model: {
                                value: _vm.create["18"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "18", $$v)
                                },
                                expression: "create['18']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(18)
                                },
                              },
                              model: {
                                value: _vm.read["18"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "18", $$v)
                                },
                                expression: "read['18']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(18)
                                },
                              },
                              model: {
                                value: _vm.update["18"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "18", $$v)
                                },
                                expression: "update['18']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(18)
                                },
                              },
                              model: {
                                value: _vm.Delete["18"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "18", $$v)
                                },
                                expression: "Delete['18']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(19)
                                },
                              },
                              model: {
                                value: _vm.permissions["19"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "19", $$v)
                                },
                                expression: "permissions['19']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["19"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(19)
                                },
                              },
                              model: {
                                value: _vm.create["19"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "19", $$v)
                                },
                                expression: "create['19']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(19)
                                },
                              },
                              model: {
                                value: _vm.read["19"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "19", $$v)
                                },
                                expression: "read['19']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(19)
                                },
                              },
                              model: {
                                value: _vm.update["19"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "19", $$v)
                                },
                                expression: "update['19']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(19)
                                },
                              },
                              model: {
                                value: _vm.Delete["19"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "19", $$v)
                                },
                                expression: "Delete['19']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(20)
                                },
                              },
                              model: {
                                value: _vm.permissions["20"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "20", $$v)
                                },
                                expression: "permissions['20']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["20"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(20)
                                },
                              },
                              model: {
                                value: _vm.create["20"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "20", $$v)
                                },
                                expression: "create['20']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(20)
                                },
                              },
                              model: {
                                value: _vm.read["20"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "20", $$v)
                                },
                                expression: "read['20']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(20)
                                },
                              },
                              model: {
                                value: _vm.update["20"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "20", $$v)
                                },
                                expression: "update['20']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(20)
                                },
                              },
                              model: {
                                value: _vm.Delete["20"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "20", $$v)
                                },
                                expression: "Delete['20']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(21)
                                },
                              },
                              model: {
                                value: _vm.permissions["21"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "21", $$v)
                                },
                                expression: "permissions['21']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["21"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(21)
                                },
                              },
                              model: {
                                value: _vm.create["21"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "21", $$v)
                                },
                                expression: "create['21']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(21)
                                },
                              },
                              model: {
                                value: _vm.read["21"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "21", $$v)
                                },
                                expression: "read['21']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(21)
                                },
                              },
                              model: {
                                value: _vm.update["21"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "21", $$v)
                                },
                                expression: "update['21']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(21)
                                },
                              },
                              model: {
                                value: _vm.Delete["21"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "21", $$v)
                                },
                                expression: "Delete['21']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(22)
                                },
                              },
                              model: {
                                value: _vm.permissions["22"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "22", $$v)
                                },
                                expression: "permissions['22']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["22"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(22)
                                },
                              },
                              model: {
                                value: _vm.create["22"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "22", $$v)
                                },
                                expression: "create['22']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(22)
                                },
                              },
                              model: {
                                value: _vm.read["22"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "22", $$v)
                                },
                                expression: "read['22']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(22)
                                },
                              },
                              model: {
                                value: _vm.update["22"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "22", $$v)
                                },
                                expression: "update['22']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(22)
                                },
                              },
                              model: {
                                value: _vm.Delete["22"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "22", $$v)
                                },
                                expression: "Delete['22']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "padding-bottom": "20px",
                            "padding-top": "20px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "20px" } },
                            [
                              _c("el-checkbox", {
                                staticClass: "clearfix",
                                attrs: { disabled: "", name: "type" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckBox(23)
                                  },
                                },
                                model: {
                                  value: _vm.permissions["23"],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.permissions, "23", $$v)
                                  },
                                  expression: "permissions['23']",
                                },
                              }),
                              _c("label", { staticClass: "label" }, [
                                _vm._v(_vm._s(_vm.name["23"])),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("el-checkbox", {
                                attrs: { label: "Create", name: "type" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckBox(23)
                                  },
                                },
                                model: {
                                  value: _vm.create["23"],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.create, "23", $$v)
                                  },
                                  expression: "create['23']",
                                },
                              }),
                              _c("el-checkbox", {
                                attrs: { label: "Read", name: "type" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckBox(23)
                                  },
                                },
                                model: {
                                  value: _vm.read["23"],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.read, "23", $$v)
                                  },
                                  expression: "read['23']",
                                },
                              }),
                              _c("el-checkbox", {
                                attrs: { label: "Update", name: "type" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckBox(23)
                                  },
                                },
                                model: {
                                  value: _vm.update["23"],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.update, "23", $$v)
                                  },
                                  expression: "update['23']",
                                },
                              }),
                              _c("el-checkbox", {
                                attrs: { label: "Delete", name: "type" },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleCheckBox(23)
                                  },
                                },
                                model: {
                                  value: _vm.Delete["23"],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.Delete, "23", $$v)
                                  },
                                  expression: "Delete['23']",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(24)
                                },
                              },
                              model: {
                                value: _vm.permissions["24"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "24", $$v)
                                },
                                expression: "permissions['24']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["24"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(24)
                                },
                              },
                              model: {
                                value: _vm.create["24"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "24", $$v)
                                },
                                expression: "create['24']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(24)
                                },
                              },
                              model: {
                                value: _vm.read["24"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "24", $$v)
                                },
                                expression: "read['24']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(24)
                                },
                              },
                              model: {
                                value: _vm.update["24"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "24", $$v)
                                },
                                expression: "update['24']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(24)
                                },
                              },
                              model: {
                                value: _vm.Delete["24"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "24", $$v)
                                },
                                expression: "Delete['24']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(25)
                                },
                              },
                              model: {
                                value: _vm.permissions["25"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "25", $$v)
                                },
                                expression: "permissions['25']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["25"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(25)
                                },
                              },
                              model: {
                                value: _vm.create["25"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "25", $$v)
                                },
                                expression: "create['25']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(25)
                                },
                              },
                              model: {
                                value: _vm.read["25"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "25", $$v)
                                },
                                expression: "read['25']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(25)
                                },
                              },
                              model: {
                                value: _vm.update["25"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "25", $$v)
                                },
                                expression: "update['25']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(25)
                                },
                              },
                              model: {
                                value: _vm.Delete["25"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "25", $$v)
                                },
                                expression: "Delete['25']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(55)
                                },
                              },
                              model: {
                                value: _vm.permissions["55"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "55", $$v)
                                },
                                expression: "permissions['55']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["55"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(55)
                                },
                              },
                              model: {
                                value: _vm.create["55"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "55", $$v)
                                },
                                expression: "create['55']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(55)
                                },
                              },
                              model: {
                                value: _vm.read["55"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "55", $$v)
                                },
                                expression: "read['55']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(55)
                                },
                              },
                              model: {
                                value: _vm.update["55"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "55", $$v)
                                },
                                expression: "update['55']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(55)
                                },
                              },
                              model: {
                                value: _vm.Delete["55"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "55", $$v)
                                },
                                expression: "Delete['55']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(26)
                                },
                              },
                              model: {
                                value: _vm.permissions["26"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "26", $$v)
                                },
                                expression: "permissions['26']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["26"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(26)
                                },
                              },
                              model: {
                                value: _vm.create["26"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "26", $$v)
                                },
                                expression: "create['26']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(26)
                                },
                              },
                              model: {
                                value: _vm.read["26"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "26", $$v)
                                },
                                expression: "read['26']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(26)
                                },
                              },
                              model: {
                                value: _vm.update["26"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "26", $$v)
                                },
                                expression: "update['26']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(26)
                                },
                              },
                              model: {
                                value: _vm.Delete["26"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "26", $$v)
                                },
                                expression: "Delete['26']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(27)
                                },
                              },
                              model: {
                                value: _vm.permissions["27"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "27", $$v)
                                },
                                expression: "permissions['27']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["27"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(27)
                                },
                              },
                              model: {
                                value: _vm.create["27"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "27", $$v)
                                },
                                expression: "create['27']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(27)
                                },
                              },
                              model: {
                                value: _vm.read["27"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "27", $$v)
                                },
                                expression: "read['27']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(27)
                                },
                              },
                              model: {
                                value: _vm.update["27"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "27", $$v)
                                },
                                expression: "update['27']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(27)
                                },
                              },
                              model: {
                                value: _vm.Delete["27"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "27", $$v)
                                },
                                expression: "Delete['27']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(28)
                                },
                              },
                              model: {
                                value: _vm.permissions["28"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "28", $$v)
                                },
                                expression: "permissions['28']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["28"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(28)
                                },
                              },
                              model: {
                                value: _vm.create["28"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "28", $$v)
                                },
                                expression: "create['28']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(28)
                                },
                              },
                              model: {
                                value: _vm.read["28"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "28", $$v)
                                },
                                expression: "read['28']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(28)
                                },
                              },
                              model: {
                                value: _vm.update["28"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "28", $$v)
                                },
                                expression: "update['28']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(28)
                                },
                              },
                              model: {
                                value: _vm.Delete["28"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "28", $$v)
                                },
                                expression: "Delete['28']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(29)
                                },
                              },
                              model: {
                                value: _vm.permissions["29"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "29", $$v)
                                },
                                expression: "permissions['29']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["29"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(29)
                                },
                              },
                              model: {
                                value: _vm.create["29"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "29", $$v)
                                },
                                expression: "create['29']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(29)
                                },
                              },
                              model: {
                                value: _vm.read["29"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "29", $$v)
                                },
                                expression: "read['29']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(29)
                                },
                              },
                              model: {
                                value: _vm.update["29"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "29", $$v)
                                },
                                expression: "update['29']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(29)
                                },
                              },
                              model: {
                                value: _vm.Delete["29"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "29", $$v)
                                },
                                expression: "Delete['29']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(52)
                                },
                              },
                              model: {
                                value: _vm.permissions["52"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "52", $$v)
                                },
                                expression: "permissions['52']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["52"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(52)
                                },
                              },
                              model: {
                                value: _vm.create["52"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "52", $$v)
                                },
                                expression: "create['52']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(52)
                                },
                              },
                              model: {
                                value: _vm.read["52"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "52", $$v)
                                },
                                expression: "read['52']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(52)
                                },
                              },
                              model: {
                                value: _vm.update["52"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "52", $$v)
                                },
                                expression: "update['52']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(52)
                                },
                              },
                              model: {
                                value: _vm.Delete["52"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "52", $$v)
                                },
                                expression: "Delete['52']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(30)
                                },
                              },
                              model: {
                                value: _vm.permissions["30"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "30", $$v)
                                },
                                expression: "permissions['30']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["30"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(30)
                                },
                              },
                              model: {
                                value: _vm.create["30"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "30", $$v)
                                },
                                expression: "create['30']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(30)
                                },
                              },
                              model: {
                                value: _vm.read["30"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "30", $$v)
                                },
                                expression: "read['30']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(30)
                                },
                              },
                              model: {
                                value: _vm.update["30"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "30", $$v)
                                },
                                expression: "update['30']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(30)
                                },
                              },
                              model: {
                                value: _vm.Delete["30"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "30", $$v)
                                },
                                expression: "Delete['30']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(31)
                                },
                              },
                              model: {
                                value: _vm.permissions["31"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "31", $$v)
                                },
                                expression: "permissions['31']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["31"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(31)
                                },
                              },
                              model: {
                                value: _vm.create["31"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "31", $$v)
                                },
                                expression: "create['31']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(31)
                                },
                              },
                              model: {
                                value: _vm.read["31"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "31", $$v)
                                },
                                expression: "read['31']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(31)
                                },
                              },
                              model: {
                                value: _vm.update["31"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "31", $$v)
                                },
                                expression: "update['31']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(31)
                                },
                              },
                              model: {
                                value: _vm.Delete["31"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "31", $$v)
                                },
                                expression: "Delete['31']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(32)
                                },
                              },
                              model: {
                                value: _vm.permissions["32"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "32", $$v)
                                },
                                expression: "permissions['32']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["32"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(32)
                                },
                              },
                              model: {
                                value: _vm.create["32"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "32", $$v)
                                },
                                expression: "create['32']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(32)
                                },
                              },
                              model: {
                                value: _vm.read["32"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "32", $$v)
                                },
                                expression: "read['32']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(32)
                                },
                              },
                              model: {
                                value: _vm.update["32"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "32", $$v)
                                },
                                expression: "update['32']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(32)
                                },
                              },
                              model: {
                                value: _vm.Delete["32"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "32", $$v)
                                },
                                expression: "Delete['32']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(56)
                                },
                              },
                              model: {
                                value: _vm.permissions["56"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "56", $$v)
                                },
                                expression: "permissions['56']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["56"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(56)
                                },
                              },
                              model: {
                                value: _vm.create["56"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "56", $$v)
                                },
                                expression: "create['56']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(56)
                                },
                              },
                              model: {
                                value: _vm.read["56"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "56", $$v)
                                },
                                expression: "read['56']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(56)
                                },
                              },
                              model: {
                                value: _vm.update["56"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "56", $$v)
                                },
                                expression: "update['56']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(56)
                                },
                              },
                              model: {
                                value: _vm.Delete["56"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "56", $$v)
                                },
                                expression: "Delete['56']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  [
                    _c("el-card", { staticClass: "box-card1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [_c("span", [_vm._v("Settings")])]
                      ),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          { staticStyle: { "padding-bottom": "20px" } },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(33)
                                },
                              },
                              model: {
                                value: _vm.permissions["33"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "33", $$v)
                                },
                                expression: "permissions['33']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["33"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(33)
                                },
                              },
                              model: {
                                value: _vm.create["33"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "33", $$v)
                                },
                                expression: "create['33']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(33)
                                },
                              },
                              model: {
                                value: _vm.read["33"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "33", $$v)
                                },
                                expression: "read['33']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(33)
                                },
                              },
                              model: {
                                value: _vm.update["33"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "33", $$v)
                                },
                                expression: "update['33']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(33)
                                },
                              },
                              model: {
                                value: _vm.Delete["33"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "33", $$v)
                                },
                                expression: "Delete['33']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(34)
                                },
                              },
                              model: {
                                value: _vm.permissions["34"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "34", $$v)
                                },
                                expression: "permissions['34']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["34"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(34)
                                },
                              },
                              model: {
                                value: _vm.create["34"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "34", $$v)
                                },
                                expression: "create['34']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(34)
                                },
                              },
                              model: {
                                value: _vm.read["34"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "34", $$v)
                                },
                                expression: "read['34']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(34)
                                },
                              },
                              model: {
                                value: _vm.update["34"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "34", $$v)
                                },
                                expression: "update['34']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(34)
                                },
                              },
                              model: {
                                value: _vm.Delete["34"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "34", $$v)
                                },
                                expression: "Delete['34']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticStyle: { "padding-bottom": "20px" } }, [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "padding-bottom": "20px",
                              "padding-top": "20px",
                            },
                          },
                          [
                            _c("el-checkbox", {
                              staticClass: "clearfix",
                              attrs: { disabled: "", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(35)
                                },
                              },
                              model: {
                                value: _vm.permissions["35"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.permissions, "35", $$v)
                                },
                                expression: "permissions['35']",
                              },
                            }),
                            _c("label", { staticClass: "label" }, [
                              _vm._v(_vm._s(_vm.name["35"])),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("el-checkbox", {
                              attrs: { label: "Create", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(35)
                                },
                              },
                              model: {
                                value: _vm.create["35"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.create, "35", $$v)
                                },
                                expression: "create['35']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Read", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(35)
                                },
                              },
                              model: {
                                value: _vm.read["35"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.read, "35", $$v)
                                },
                                expression: "read['35']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Update", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(35)
                                },
                              },
                              model: {
                                value: _vm.update["35"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.update, "35", $$v)
                                },
                                expression: "update['35']",
                              },
                            }),
                            _c("el-checkbox", {
                              attrs: { label: "Delete", name: "type" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleCheckBox(35)
                                },
                              },
                              model: {
                                value: _vm.Delete["35"],
                                callback: function ($$v) {
                                  _vm.$set(_vm.Delete, "35", $$v)
                                },
                                expression: "Delete['35']",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "75%", "margin-left": "5%" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-width": "35%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "roleName", label: "Role Name" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "roleName", $$v)
                      },
                      expression: "temp.roleName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisabled },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteLabelDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteLabelDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the MasterRole!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteLabelDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.deleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }