var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Profit" }, [
    _c(
      "div",
      {
        staticStyle: {
          "padding-bottom": "8px",
          "text-align": "left",
          "padding-top": "5px",
          "padding-left": "5px",
          "padding-right": "80%",
        },
      },
      [
        _c("el-input", {
          attrs: {
            size: "small",
            placeholder: "Type and press enter to search",
          },
          model: {
            value: _vm.search,
            callback: function ($$v) {
              _vm.search = $$v
            },
            expression: "search",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "ProfitTable" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.tableData1.filter(function (data) {
                return (
                  !_vm.search ||
                  data.skill.toLowerCase().includes(_vm.search.toLowerCase())
                )
              }),
              "default-sort": { order: "ascending" },
              "show-summary": "",
              "summary-method": _vm.getSummariesProfit,
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                label: "Skill",
                width: "200",
                sortable: "",
                prop: "skill",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "Budget",
                width: "150",
                align: "center",
                sortable: "",
                prop: "estimatedBudget",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "GrandTotal1" }, [
                        _vm._v(_vm._s(scope.row.estimatedBudget)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Allocated",
                width: "150",
                align: "center",
                sortable: "",
                prop: "allocatedAmount",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "Burned",
                width: "150",
                align: "center",
                sortable: "",
                prop: "burned",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "Remaining",
                width: "150",
                align: "center",
                sortable: "",
                prop: "remaining",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "Allocated %",
                width: "150",
                align: "center",
                sortable: "",
                prop: "allocatedPercentage",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "Burned %",
                width: "150",
                align: "center",
                sortable: "",
                prop: "burnedPercentage",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: "Profit",
                width: "150",
                align: "center",
                sortable: "",
                prop: "profit",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "GrandTotal1" }, [
                        _vm._v(_vm._s(scope.row.profit)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Profit %",
                width: "150",
                align: "center",
                sortable: "",
                prop: "profitPercentage",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", { staticClass: "GrandTotal1" }, [
                        _vm._v(_vm._s(scope.row.profitPercentage)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                label: "Status",
                width: "150",
                align: "center",
                sortable: "",
                prop: "status",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }