"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _masterEmployee = _interopRequireDefault(require("@/views/master-employee"));
var _masterSalary = _interopRequireDefault(require("@/views/master-salary"));
var _masterMilestone = _interopRequireDefault(require("@/views/master-milestone"));
var _masterProject = _interopRequireDefault(require("@/views/master-project"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    Employee: _masterEmployee.default,
    Salary: _masterSalary.default,
    Milestone: _masterMilestone.default,
    Project: _masterProject.default
  },
  data: function data() {
    return {
      tabPosition: 'top',
      activeName: 'first'
    };
  }
};
exports.default = _default;