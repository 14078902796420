var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "5px" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "MasterEmployeeLabel",
          staticStyle: { height: "700px" },
          attrs: { type: "border-card", "tab-position": _vm.tabPosition },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Designation", name: "first" } },
            [[_c("Designation")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Department", name: "second" } },
            [[_c("Department")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Category", name: "third" } },
            [[_c("Category")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Employee Type", name: "fourth" } },
            [[_c("EmployeeType")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Experience", name: "fifth" } },
            [[_c("Experience")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Grade", name: "sixth" } },
            [[_c("Grade")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Level", name: "seventh" } },
            [[_c("Level")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Skill", name: "eighth" } },
            [[_c("Skill")]],
            2
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "Skill Level", name: "nineth" } },
            [[_c("SkillLevel")]],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }