"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _masterMilestoneStatus = _interopRequireDefault(require("@/views/master-milestone/master-milestoneStatus"));
var _masterCurrencytype = _interopRequireDefault(require("@/views/master-milestone/master-currencytype"));
var _masterMilestonepaymentstatus = _interopRequireDefault(require("@/views/master-milestone/master-milestonepaymentstatus"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    MilestoneStatus: _masterMilestoneStatus.default,
    CurrencyType: _masterCurrencytype.default,
    MilestonePaymentStatus: _masterMilestonepaymentstatus.default
  },
  data: function data() {
    return {
      tabPosition: 'left',
      activeName: 'first'
    };
  }
};
exports.default = _default;