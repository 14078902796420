"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _masterProjecttype = _interopRequireDefault(require("@/views/master-project/master-projecttype"));
var _masterIncedentalExpense = _interopRequireDefault(require("@/views/master-project/master-incedentalExpense"));
var _masterAdditionalToolExpense = _interopRequireDefault(require("@/views/master-project/master-additionalToolExpense"));
var _masterRequestType = _interopRequireDefault(require("@/views/master-project/master-requestType"));
var _masterProjectstatus = _interopRequireDefault(require("@/views/master-project/master-projectstatus"));
var _vuex = require("vuex");
var _moment = _interopRequireDefault(require("moment"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ProjectType: _masterProjecttype.default,
    IncedentalExpense: _masterIncedentalExpense.default,
    AdditionalToolExpense: _masterAdditionalToolExpense.default,
    RequestType: _masterRequestType.default,
    ProjectStatus: _masterProjectstatus.default
  },
  data: function data() {
    return {
      tabPosition: 'left',
      activeName: 'first'
    };
  }
};
exports.default = _default;