var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "5px",
            "padding-left": "10px",
            "padding-top": "5px",
            "padding-right": "10px",
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              staticStyle: { width: "10%" },
              attrs: { placeholder: "Select Report", disabled: _vm.isDisabled },
              on: { change: _vm.getDepartments },
              model: {
                value: _vm.employeelist,
                callback: function ($$v) {
                  _vm.employeelist = $$v
                },
                expression: "employeelist",
              },
            },
            _vm._l(_vm.employeeApi, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.label, value: item.name },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "10%" },
              attrs: {
                filterable: "",
                disabled: _vm.isDisabled,
                placeholder: "Select Department",
              },
              on: { change: _vm.getmasterList },
              model: {
                value: _vm.departmentId,
                callback: function ($$v) {
                  _vm.departmentId = $$v
                },
                expression: "departmentId",
              },
            },
            _vm._l(_vm.departments, function (item) {
              return _c("el-option", {
                key: item.labelId,
                attrs: { label: item.labelName, value: item.labelId },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              staticStyle: { width: "10%" },
              attrs: { placeholder: "Select", disabled: _vm.isDisabled },
              on: { change: _vm.getmasterList },
              model: {
                value: _vm.monthlyOrYearly,
                callback: function ($$v) {
                  _vm.monthlyOrYearly = $$v
                },
                expression: "monthlyOrYearly",
              },
            },
            _vm._l(_vm.monthlyYearly, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.label, value: item.name },
              })
            }),
            1
          ),
          this.canHideMonth == true
            ? _c("el-date-picker", {
                staticStyle: { width: "10%" },
                attrs: {
                  type: "month",
                  disabled: _vm.isDisabled,
                  placeholder: "Pick a month",
                },
                on: { change: _vm.getmasterList },
                model: {
                  value: _vm.dropDownMonth,
                  callback: function ($$v) {
                    _vm.dropDownMonth = $$v
                  },
                  expression: "dropDownMonth",
                },
              })
            : _vm._e(),
          this.canHideYear == true
            ? _c("el-date-picker", {
                staticStyle: { width: "10%" },
                attrs: {
                  disabled: _vm.isDisabled,
                  type: "year",
                  placeholder: "Pick a year",
                },
                on: { change: _vm.getmasterList },
                model: {
                  value: _vm.dropDownYear,
                  callback: function ($$v) {
                    _vm.dropDownYear = $$v
                  },
                  expression: "dropDownYear",
                },
              })
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "empReports",
              staticStyle: { float: "right" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.HandleExport()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-download" }),
              _c("span", [_vm._v("Export")]),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "EmployeeReport" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                "show-summary": "",
                "summary-method": _vm.getEmployeeReports,
                "element-loading-text": "Loading...",
                "element-loading-spinner": "el-icon-loading",
                data: _vm.tableData,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  sortable: "",
                  width: "160px",
                  label: "Employee ID",
                  prop: "employeeCode",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200px",
                  sortable: "",
                  label: "Employee Name",
                  prop: "employeeName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "140px",
                  sortable: "",
                  label: "Deparment",
                  prop: "departmentName",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "170px",
                  label: "Date Of Joining",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.joiningDate))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  width: "170px",
                  label: "Date Of Exit",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDate")(scope.row.exitDate))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.sample, function (item1) {
                return [
                  _c("el-table-column", {
                    attrs: { align: "center", label: item1, width: "90px;" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return _vm._l(
                              props.row.monthlySalaryList,
                              function (item2) {
                                return _c("div", [
                                  item2.dateMonthYear == item1
                                    ? _c("div", [
                                        _vm._v(
                                          " " + _vm._s(item2.salaryAmount) + " "
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }
                            )
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ]
              }),
              _c("el-table-column", {
                attrs: {
                  width: "200px",
                  align: "center",
                  label: "Total",
                  prop: "totalSalaryAmount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticClass: "EmployeeReportBold" }, [
                          _vm._v(_vm._s(scope.row.totalSalaryAmount)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getmasterList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }