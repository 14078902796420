var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "8px",
            "text-align": "left",
            "padding-top": "5px",
            "padding-left": "10px",
          },
        },
        [
          _c("el-date-picker", {
            staticStyle: { width: "220px" },
            attrs: {
              disabled: _vm.isDisabled,
              type: "monthrange",
              "range-separator": "-",
              format: "yyyy-MM-dd",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
            },
            on: {
              change: function ($event) {
                return _vm.getEmployeeEfficiencyList()
              },
            },
            model: {
              value: _vm.dualDate,
              callback: function ($$v) {
                _vm.dualDate = $$v
              },
              expression: "dualDate",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              staticStyle: { width: "10%" },
              attrs: {
                placeholder: "Select Category",
                disabled: _vm.dropdownDisable,
              },
              on: { change: _vm.getsubCategoryList },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.value, value: item.id },
              })
            }),
            1
          ),
          this.CanHide == true
            ? _c(
                "el-select",
                {
                  staticClass: "header-search-select",
                  staticStyle: { width: "15%" },
                  attrs: {
                    placeholder: "Select Sub Category",
                    disabled: _vm.dropdownDisable,
                  },
                  on: { change: _vm.getEmployeeEfficiencyList },
                  model: {
                    value: _vm.subtypeId,
                    callback: function ($$v) {
                      _vm.subtypeId = $$v
                    },
                    expression: "subtypeId",
                  },
                },
                _vm._l(_vm.subCategory, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "effiexport",
              staticStyle: { float: "right" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.HandleExport()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-download" }),
              _c("span", [_vm._v("Export")]),
            ]
          ),
          _c("el-input", {
            staticStyle: { width: "20%", float: "right" },
            attrs: {
              disabled: _vm.DisabledOnLoad,
              size: "small",
              placeholder: "Type and press enter to search",
            },
            on: {
              input: function ($event) {
                return _vm.getCount(_vm.search)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchByName()
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "employee-pl",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "default-sort": { prop: "name", order: "ascending" },
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            height: "568",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "140px",
              sortable: "",
              label: "Employee ID",
              fixed: "",
              prop: "employeeCode",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              sortable: "",
              label: "Employee Name",
              fixed: "",
              prop: "name",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              sortable: "",
              label: "Department",
              prop: "department",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "170px",
              label: "Date Of Joining",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.dateOfJoining))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "170px", label: "Date Of Exit" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.exitDate))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.Dates, function (item1) {
            return [
              _c(
                "el-table-column",
                { attrs: { align: "center", label: item1, width: "100px;" } },
                [
                  _c("el-table-column", {
                    attrs: { align: "center", label: "Salary" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._l(
                                props.row.monthlyEfficiencyList,
                                function (item2) {
                                  return [
                                    item2.dateMonthYear == item1
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(_vm._s(item2.salaryAmount)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "Fixed Cost" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._l(
                                props.row.monthlyEfficiencyList,
                                function (item2) {
                                  return [
                                    item2.dateMonthYear == item1
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(_vm._s(item2.fixedAmount)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "Working Hours" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._l(
                                props.row.monthlyEfficiencyList,
                                function (item2) {
                                  return [
                                    item2.dateMonthYear == item1
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(_vm._s(item2.workingHours)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "Billable Amount" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._l(
                                props.row.monthlyEfficiencyList,
                                function (item2) {
                                  return [
                                    item2.dateMonthYear == item1
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(item2.billableAmount)
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { align: "center", label: "Profit" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (props) {
                            return [
                              _vm._l(
                                props.row.monthlyEfficiencyList,
                                function (item2) {
                                  return [
                                    item2.dateMonthYear == item1
                                      ? _c("span", [
                                          _c("span", [
                                            _vm._v(_vm._s(item2.profit)),
                                          ]),
                                        ])
                                      : _vm._e(),
                                  ]
                                }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
            ]
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Total Salary",
              prop: "totalSalaryAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Total Fixed Cost",
              prop: "totalFixedAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Billable Hours",
              prop: "billableWorkingHours",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Billable Amount",
              prop: "billableAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              label: "Profit",
              prop: "profit",
            },
          }),
        ],
        2
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getEmployeeEfficiencyList()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }