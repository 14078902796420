var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "8px",
            "text-align": "left",
            "padding-top": "5px",
            "padding-left": "10px",
          },
        },
        [
          _c("el-date-picker", {
            attrs: {
              disabled: _vm.isDisabled,
              type: "monthrange",
              "range-separator": "-",
              format: "dd-MM-yyyy",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
            },
            on: {
              change: function ($event) {
                return _vm.getEmployeeCountList()
              },
            },
            model: {
              value: _vm.dualDate,
              callback: function ($$v) {
                _vm.dualDate = $$v
              },
              expression: "dualDate",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              attrs: {
                placeholder: "Select Category",
                disabled: _vm.dropdownDisable,
              },
              on: { change: _vm.getsubCategoryList },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.value, value: item.id },
              })
            }),
            1
          ),
          this.CanHide == true
            ? _c(
                "el-select",
                {
                  staticClass: "header-search-select",
                  attrs: {
                    placeholder: "Select sub category",
                    disabled: _vm.dropdownDisable,
                  },
                  on: { change: _vm.subCategoryChange },
                  model: {
                    value: _vm.subtypeId,
                    callback: function ($$v) {
                      _vm.subtypeId = $$v
                    },
                    expression: "subtypeId",
                  },
                },
                _vm._l(_vm.subCategory, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id },
                  })
                }),
                1
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticClass: "empCountExport",
              staticStyle: { float: "right" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.HandleExport()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-download" }),
              _c("span", [_vm._v("Export")]),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "emp-count",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            height: "630",
          },
        },
        [
          _c("el-table-column", {
            attrs: { fixed: "", width: "110px", label: "Label", prop: "label" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.label != "Total" &&
                    scope.row.label != "New" &&
                    scope.row.label != "Resigned"
                      ? _c("span", { staticClass: "black" }, [
                          _vm._v(_vm._s(scope.row.label)),
                        ])
                      : _vm._e(),
                    scope.row.label == "Total"
                      ? _c("span", { staticClass: "blue" }, [
                          _vm._v(_vm._s(scope.row.label)),
                        ])
                      : _vm._e(),
                    scope.row.label == "New"
                      ? _c("span", { staticClass: "green" }, [
                          _vm._v(_vm._s(scope.row.label)),
                        ])
                      : _vm._e(),
                    scope.row.label == "Resigned"
                      ? _c("span", { staticClass: "red" }, [
                          _vm._v(_vm._s(scope.row.label)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._l(_vm.Dates, function (item1) {
            return [
              _c("el-table-column", {
                attrs: { align: "center", label: item1, width: "95px;" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return _vm._l(props.row.count, function (item2) {
                          return _c("span", [
                            item2.day == item1 &&
                            props.row.label != "Total" &&
                            props.row.label != "New" &&
                            props.row.label != "Resigned"
                              ? _c("span", { staticClass: "black" }, [
                                  _vm._v(_vm._s(item2.employeeCount)),
                                ])
                              : _vm._e(),
                            item2.day == item1 && props.row.label == "Total"
                              ? _c("span", { staticClass: "blue" }, [
                                  _vm._v(_vm._s(item2.employeeCount)),
                                ])
                              : _vm._e(),
                            item2.day == item1 && props.row.label == "New"
                              ? _c("span", { staticClass: "green" }, [
                                  _vm._v(_vm._s(item2.employeeCount)),
                                ])
                              : _vm._e(),
                            item2.day == item1 && props.row.label == "Resigned"
                              ? _c("span", { staticClass: "red" }, [
                                  _vm._v(_vm._s(item2.employeeCount)),
                                ])
                              : _vm._e(),
                          ])
                        })
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }