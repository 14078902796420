var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { margin: "5px" } },
    [
      _c(
        "el-tabs",
        {
          staticClass: "MasterSalaryLabel",
          staticStyle: { height: "700px" },
          attrs: { type: "border-card", "tab-position": _vm.tabPosition },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Pay Status", name: "first" } },
            [[_c("PayStatus")]],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }