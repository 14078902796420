"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.array.includes.js");
require("core-js/modules/es.string.includes.js");
require("core-js/modules/es.object.to-string.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _router = _interopRequireDefault(require("@/router"));
// axios
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // apibase_url
  timeout: 60000
});

// request interceptor
service.interceptors.request.use(function (config) {
  if (_store.default.getters.token) {
    config.headers['Authorization'] = 'Bearer ' + (0, _auth.getToken)();
  }
  config.headers['Accept'] = '*/*';
  config.headers['Content-Type'] = 'application/json; charset=utf-8';
  var sam = config.url;
  var tam = sam.includes("Export");
  if (tam) {
    config.headers['responseType'] = 'blob';
  }
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  /**
   * code为非20000是抛错 可结合自己业务进行修改
   */
  var res = response.data;
  // if (!res || res.error) {
  //   Message({
  //     message: res.message,
  //     type: 'error',
  //     duration: 5 * 1000
  //   })
  //   if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
  //     MessageBox.confirm(
  //       'You have been logged out，You can cancel to stay on this page，or log in again',
  //       'Confirm Logout',
  //       {
  //         confirmButtonText: 'Ok',
  //         cancelButtonText: 'Cancel',
  //         type: 'warning'
  //       }
  //     ).then(() => {
  //       store.dispatch('FedLogOut').then(() => {
  //         location.reload()
  //       })
  //     })
  //   }
  //   return Promise.reject('error')
  // } else {
  // return res
  // }

  return res;
}, function (error) {
  if (error.response.status == 401) {
    _store.default.dispatch('user/logout');
    _router.default.push({
      path: '/login'
    });
  } else if (error.response.status == 404) {
    //router.push({path:'/404'})
  }
  // Message({
  //   message: error.message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })
  return Promise.reject(error);
});
var _default = service; // import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// // create an axios instance
// const service = axios.create({
//   baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
//   // withCredentials: true, // send cookies when cross-domain requests
//   timeout: 5000 // request timeout
// })
// // request interceptor
// service.interceptors.request.use(
//   config => {
//     // do something before request is sent
//     if (store.getters.token) {
//       // let each request carry token
//       // ['X-Token'] is a custom headers key
//       // please modify it according to the actual situation
//       config.headers['X-Token'] = getToken()
//     }
//     return config
//   },
//   error => {
//     // do something with request error
//     console.log(error) // for debug
//     return Promise.reject(error)
//   }
// )
// // response interceptor
// service.interceptors.response.use(
//   /**
//    * If you want to get http information such as headers or status
//    * Please return  response => response
//   */
//   /**
//    * Determine the request status by custom code
//    * Here is just an example
//    * You can also judge the status by HTTP Status Code
//    */
//   response => {
//     const res = response.data
//     // if the custom code is not 20000, it is judged as an error.
//     if (res.code !== 20000) {
//       Message({
//         message: res.message || 'Error',
//         type: 'error',
//         duration: 5 * 1000
//       })
//       // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
//       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
//         // to re-login
//         MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
//           confirmButtonText: 'Re-Login',
//           cancelButtonText: 'Cancel',
//           type: 'warning'
//         }).then(() => {
//           store.dispatch('user/resetToken').then(() => {
//             location.reload()
//           })
//         })
//       }
//       return Promise.reject(new Error(res.message || 'Error'))
//     } else {
//       return res
//     }
//   },
//   error => {
//     console.log('err' + error) // for debug
//     Message({
//       message: error.message,
//       type: 'error',
//       duration: 5 * 1000
//     })
//     return Promise.reject(error)
//   }
// )
// export default service
exports.default = _default;