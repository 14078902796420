var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticClass: "CardProjects" },
        [
          _c("el-row", { staticClass: "RowProjects" }, [
            _c("i", {
              staticClass: "el-icon-back",
              staticStyle: {
                color: "orange",
                "font-size": "18px",
                "padding-right": "5px",
              },
              on: {
                click: function ($event) {
                  return _vm.handleBackButton()
                },
              },
            }),
            _c(
              "span",
              { staticStyle: { color: "orange", "font-size": "19px" } },
              [_vm._v("Back")]
            ),
          ]),
          _c(
            "el-row",
            { staticClass: "RowProjects" },
            [
              _c("el-col", { attrs: { span: 7 } }, [
                _c("span", [
                  _vm._v("Project Name :" + _vm._s(_vm.tableData.projectName)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "Project Cost : " +
                      _vm._s(parseInt(_vm.tableData1[0].deliveryCost))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "Paid Amount : " +
                      _vm._s(parseInt(_vm.tableData1[0].paidAmount))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "Balance Amount : " +
                      _vm._s(parseInt(_vm.tableData1[0].balanceAmount))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 7 } }, [
                _c("span", [
                  _vm._v(
                    "Resource Cost : " +
                      _vm._s(parseInt(_vm.tableData1[0].resourceFixedCost))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "Additional Tool Expense : " +
                      _vm._s(parseInt(_vm.tableData1[0].additionalToolExpenses))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "Incidental Expense : " +
                      _vm._s(parseInt(_vm.tableData1[0].incidentalExpenses))
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v("Buffer : " + _vm._s(_vm.tableData1[0].buffer)),
                ]),
              ]),
              _c("el-col", { attrs: { span: 7 } }, [
                _c("span", [_vm._v("Allocated : " + _vm._s(_vm.allocated))]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [_vm._v("Burned : " + _vm._s(_vm.burned))]),
              ]),
              _c("el-col", { attrs: { span: 5 } }, [
                _c("span", [
                  _vm._v(
                    "Burned% On Resource : " + _vm._s(_vm.burnedPercentage)
                  ),
                ]),
              ]),
              _c("el-col", { attrs: { span: 3 } }, [
                _c("span", [
                  this.canEdit == true
                    ? _c("i", {
                        staticClass: "el-icon-edit",
                        staticStyle: {
                          color: "orange",
                          "font-size": "20px",
                          "padding-right": "10px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit()
                          },
                        },
                      })
                    : _vm._e(),
                  this.canDelete == true
                    ? _c("i", {
                        staticClass: "el-icon-delete",
                        staticStyle: {
                          color: "orange",
                          "font-size": "20px",
                          "padding-right": "10px",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDelete()
                          },
                        },
                      })
                    : _vm._e(),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "50%",
            title: _vm.textMap[_vm.dialogStatus],
            "close-on-click-modal": false,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "100%", "margin-left": "40px" },
              attrs: {
                rules: _vm.rules,
                inline: true,
                model: _vm.temp,
                "label-width": "200px",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Project Name", prop: "prePlanProjectId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        filterable: "",
                        placeholder: "Please select",
                        disabled: "",
                      },
                      model: {
                        value: _vm.temp.prePlanProjectId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "prePlanProjectId", $$v)
                        },
                        expression: "temp.prePlanProjectId",
                      },
                    },
                    _vm._l(_vm.preProjectNames, function (item) {
                      return _c("el-option", {
                        key: item.prePlanProjectId,
                        attrs: {
                          label: item.prePlanProject,
                          value: item.prePlanProjectId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "clientId", label: "Account/Client" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: {
                        filterable: "",
                        placeholder: "Please select",
                        disabled: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.HandleClient(_vm.temp.clientId)
                        },
                      },
                      model: {
                        value: _vm.temp.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "clientId", $$v)
                        },
                        expression: "temp.clientId",
                      },
                    },
                    _vm._l(_vm.accounts, function (item) {
                      return _c("el-option", {
                        key: item.accountId,
                        attrs: {
                          label: item.accountName,
                          value: item.accountId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "contactId", label: "Contact Person" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.contactId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "contactId", $$v)
                        },
                        expression: "temp.contactId",
                      },
                    },
                    _vm._l(_vm.contacts, function (item) {
                      return _c("el-option", {
                        key: item.clientId,
                        attrs: { label: item.clientName, value: item.clientId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "accountManagerId", label: "Account Manager" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.accountManagerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "accountManagerId", $$v)
                        },
                        expression: "temp.accountManagerId",
                      },
                    },
                    _vm._l(_vm.owners, function (item) {
                      return _c("el-option", {
                        key: item.employeeID,
                        attrs: {
                          label: item.employeeName,
                          value: item.employeeID,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "deliveryManagerId",
                    label: "Delivery Manager",
                  },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.deliveryManagerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "deliveryManagerId", $$v)
                        },
                        expression: "temp.deliveryManagerId",
                      },
                    },
                    _vm._l(_vm.owners, function (item) {
                      return _c("el-option", {
                        key: item.employeeID,
                        attrs: {
                          label: item.employeeName,
                          value: item.employeeID,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "projectManagerId", label: "Project Manager" },
                },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.projectManagerId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "projectManagerId", $$v)
                        },
                        expression: "temp.projectManagerId",
                      },
                    },
                    _vm._l(_vm.owners, function (item) {
                      return _c("el-option", {
                        key: item.employeeID,
                        attrs: {
                          label: item.employeeName,
                          value: item.employeeID,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "technicalLeadId", label: "Technical Lead" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.technicalLeadId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "technicalLeadId", $$v)
                        },
                        expression: "temp.technicalLeadId",
                      },
                    },
                    _vm._l(_vm.owners, function (item) {
                      return _c("el-option", {
                        key: item.employeeID,
                        attrs: {
                          label: item.employeeName,
                          value: item.employeeID,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Start & End Date" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "Start date",
                      "end-placeholder": "End date",
                    },
                    model: {
                      value: _vm.temp.decidedDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "decidedDate", $$v)
                      },
                      expression: "temp.decidedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Actual Dates" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "daterange",
                      "range-separator": "-",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      "start-placeholder": "Actual Start date",
                      "end-placeholder": "Actual End date",
                    },
                    model: {
                      value: _vm.temp.actualDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "actualDate", $$v)
                      },
                      expression: "temp.actualDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "Billing", prop: "isBilling" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.temp.isBilling,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "isBilling", $$v)
                        },
                        expression: "temp.isBilling",
                      },
                    },
                    [
                      _c("el-radio", {
                        attrs: { label: "Billable", value: "Billable" },
                      }),
                      _c("el-radio", {
                        attrs: { label: "Non Billable", value: "NonBillable" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "projectTypeId", label: "Project Type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.projectTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "projectTypeId", $$v)
                        },
                        expression: "temp.projectTypeId",
                      },
                    },
                    _vm._l(_vm.projecttypes, function (item) {
                      return _c("el-option", {
                        key: item.labelId,
                        attrs: { label: item.labelName, value: item.labelId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "projectStatusId", label: "Project Status" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      model: {
                        value: _vm.temp.projectStatusId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "projectStatusId", $$v)
                        },
                        expression: "temp.projectStatusId",
                      },
                    },
                    _vm._l(_vm.projectstatustypes, function (item) {
                      return _c("el-option", {
                        key: item.labelId,
                        attrs: { label: item.labelName, value: item.labelId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.dialogStatus === "update"
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "Project End Date",
                        prop: "projectStatusEndDate",
                      },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "date",
                          placeholder: "Please pick a date",
                          format: "dd-MM-yyyy",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.temp.projectStatusEndDate,
                          callback: function ($$v) {
                            _vm.$set(_vm.temp, "projectStatusEndDate", $$v)
                          },
                          expression: "temp.projectStatusEndDate",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                {
                  staticStyle: { display: "block" },
                  attrs: { label: "Project Cost", prop: "deliveryCost" },
                },
                [
                  _c("el-input", {
                    attrs: {
                      "controls-position": "right",
                      disabled: "",
                      placeholder: "Project Cost",
                    },
                    on: { input: _vm.calculate },
                    model: {
                      value: _vm.temp.deliveryCost,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "deliveryCost", _vm._n($$v))
                      },
                      expression: "temp.deliveryCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticClass: "resource",
                  attrs: {
                    prop: "resourceFixedCostPercentage",
                    label: "Resource Cost %",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      id: "resourceid",
                      maxlength: "3",
                      "controls-position": "right",
                    },
                    on: {
                      focus: function ($event) {
                        return _vm.focusResource(
                          _vm.temp.resourceFixedCostPercentage
                        )
                      },
                      change: _vm.resourceCost,
                    },
                    model: {
                      value: _vm.temp.resourceFixedCostPercentage,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.temp,
                          "resourceFixedCostPercentage",
                          _vm._n($$v)
                        )
                      },
                      expression: "temp.resourceFixedCostPercentage",
                    },
                  }),
                  _c("span", {
                    staticClass: "el-form-item__error",
                    attrs: { id: "resource" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "resourceFixedCost" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.temp.resourceFixedCost,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "resourceFixedCost", _vm._n($$v))
                      },
                      expression: "temp.resourceFixedCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "additionalToolExpensesPercentage",
                    label: "Additional Expense %",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      id: "additionalid",
                      maxlength: "3",
                      "controls-position": "right",
                    },
                    on: {
                      focus: function ($event) {
                        return _vm.focusAdditional(
                          _vm.temp.additionalToolExpensesPercentage
                        )
                      },
                      change: _vm.additionalCost,
                    },
                    model: {
                      value: _vm.temp.additionalToolExpensesPercentage,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.temp,
                          "additionalToolExpensesPercentage",
                          _vm._n($$v)
                        )
                      },
                      expression: "temp.additionalToolExpensesPercentage",
                    },
                  }),
                  _c("span", {
                    staticClass: "el-form-item__error",
                    attrs: { id: "additional" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "additionalToolExpenses" } },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", disabled: "" },
                    model: {
                      value: _vm.temp.additionalToolExpenses,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.temp,
                          "additionalToolExpenses",
                          _vm._n($$v)
                        )
                      },
                      expression: "temp.additionalToolExpenses",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "incidentalExpensesPercentage",
                    label: "Incidental Expense %",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      id: "incidentid",
                      maxlength: "3",
                      "controls-position": "right",
                    },
                    on: {
                      focus: function ($event) {
                        return _vm.focusIncedental(
                          _vm.temp.incidentalExpensesPercentage
                        )
                      },
                      change: _vm.incedentalCost,
                    },
                    model: {
                      value: _vm.temp.incidentalExpensesPercentage,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.temp,
                          "incidentalExpensesPercentage",
                          _vm._n($$v)
                        )
                      },
                      expression: "temp.incidentalExpensesPercentage",
                    },
                  }),
                  _c("span", {
                    staticClass: "el-form-item__error",
                    attrs: { id: "incident" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "incidentalExpenses" } },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", disabled: "" },
                    model: {
                      value: _vm.temp.incidentalExpenses,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "incidentalExpenses", _vm._n($$v))
                      },
                      expression: "temp.incidentalExpenses",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "BufferPercentage", label: "buffer %" } },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", disabled: "" },
                    on: { input: _vm.calcvalue3 },
                    model: {
                      value: _vm.temp.bufferPercentage,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "bufferPercentage", $$v)
                      },
                      expression: "temp.bufferPercentage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "buffer" } },
                [
                  _c("el-input", {
                    attrs: { "controls-position": "right", disabled: "" },
                    model: {
                      value: _vm.temp.buffer,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "buffer", $$v)
                      },
                      expression: "temp.buffer",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.dialogFormVisible = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.isDisabled },
                  on: {
                    click: function ($event) {
                      _vm.dialogStatus === "create"
                        ? _vm.createData()
                        : _vm.updateData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteProjectDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteProjectDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the project")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteProjectDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.DeleteDisbled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-tabs",
        {
          staticClass: "ProjectsTabs",
          attrs: {
            type: "border-card",
            "active-name": "first",
            "fixed-tabs": "",
          },
        },
        [
          this.canReadProjectPlan == true
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Planned", name: "first" } },
                [
                  [
                    _c("Resourse", {
                      attrs: {
                        Name: _vm.tableData.projectName,
                        id: _vm.tableData.projectid,
                      },
                      on: { finished1: _vm.finished1 },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
          this.canReadIncidental == true
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Incidental Expense", name: "second" } },
                [
                  [
                    _c("IncedentalExpense", {
                      attrs: {
                        Name: _vm.tableData.projectName,
                        id: _vm.tableData.projectid,
                      },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
          this.canReadAdditional == true
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Additional Tool Expense", name: "third" } },
                [
                  [
                    _c("AdditionalExpense", {
                      attrs: {
                        Name: _vm.tableData.projectName,
                        id: _vm.tableData.projectid,
                      },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
          this.canReadProfit == true
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Profit", name: "fourth" } },
                [
                  [
                    _c("Profit", {
                      attrs: {
                        Name: _vm.tableData.projectName,
                        id: _vm.tableData.projectid,
                        ProjectCost: _vm.tableData1[0].deliveryCost,
                        Resource: _vm.tableData1[0].resourceFixedCost,
                        Additional: _vm.tableData1[0].additionalToolExpenses,
                        Incidental: _vm.tableData1[0].incidentalExpenses,
                        buffer: _vm.tableData1[0].buffer,
                      },
                      on: { finished: _vm.finished },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
          this.canReadPCR == true
            ? _c(
                "el-tab-pane",
                { attrs: { label: "PCR", name: "fifth" } },
                [
                  [
                    _c("PCR", {
                      attrs: {
                        Name: _vm.tableData.projectName,
                        id: _vm.tableData.projectid,
                      },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
          this.canReadPayment == true
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Payment", name: "sixth" } },
                [
                  [
                    _c("Payment", {
                      attrs: {
                        Name: _vm.tableData.projectName,
                        id: _vm.tableData.projectid,
                        prePlanProjectId: _vm.tableData1[0].prePlanProjectId,
                      },
                    }),
                  ],
                ],
                2
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }