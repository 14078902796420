var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "8px",
            "text-align": "left",
            "padding-top": "5px",
            "padding-left": "10px",
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: { disabled: _vm.isDisabled, placeholder: "Please select" },
              on: {
                change: function ($event) {
                  return _vm.getProjectPandL()
                },
              },
              model: {
                value: _vm.dropdown.status,
                callback: function ($$v) {
                  _vm.$set(_vm.dropdown, "status", $$v)
                },
                expression: "dropdown.status",
              },
            },
            _vm._l(_vm.projectstatustypes1, function (item) {
              return _c("el-option", {
                key: item.labelId,
                attrs: { label: item.labelName, value: item.labelId },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "pandlexport",
              staticStyle: { float: "right" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.HandleExport()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-download" }),
              _c("span", [_vm._v("Export")]),
            ]
          ),
          _c("el-input", {
            staticStyle: { width: "20%", float: "right" },
            attrs: {
              disabled: _vm.isDisabled,
              size: "small",
              placeholder: "Type and press enter to search",
            },
            on: {
              input: function ($event) {
                return _vm.getCount(_vm.search)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchByName()
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            "default-sort": { order: "ascending" },
            height: "570",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              label: "Project Name",
              width: "200",
              sortable: "",
              prop: "projectName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Budget",
              width: "120",
              sortable: "",
              prop: "projectBudget",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Allocated",
              width: "120",
              sortable: "",
              prop: "projectAllocatedAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Burned",
              width: "120",
              sortable: "",
              prop: "projectBurnedAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Remaining",
              width: "125",
              sortable: "",
              prop: "projectRemainingAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Allocated %",
              width: "130",
              sortable: "",
              prop: "projectAllocatedPercentage",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Burned %",
              width: "120",
              sortable: "",
              prop: "projectBurnedPercentage",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Profit", sortable: "", prop: "projectProfit" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "Profit %",
              width: "110",
              sortable: "",
              prop: "projectProfitPercentage",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Status", sortable: "", prop: "projectStatus" },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp1.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp1, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getProjectPandL()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }