var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        staticStyle: {
          "padding-bottom": "8px",
          "text-align": "right",
          "padding-top": "5px",
        },
      }),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            height: "500",
            "default-sort": { prop: "employeeCode", order: "ascending" },
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "300px",
              sortable: "",
              label: "Milestone Name",
              prop: "milestone",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "180px",
              sortable: "",
              label: "Milestone Status",
              prop: "milestoneStatus",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150px",
              label: "Planned End Date",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.plannedEndDate))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "150px",
              label: "Actual End Date",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.actualEndDate))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "190px",
              label: "Payment Planned Date",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(scope.row.paymentPlannedDate)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              label: "Payment Probability",
              prop: "paymentProbability",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "200px",
              sortable: "",
              label: "BCY Amount",
              prop: "bcyAmount",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", { staticClass: "bcyMilestone" }, [
                      _vm._v(_vm._s(scope.row.bcyAmount)),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }