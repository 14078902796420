"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
require("core-js/modules/es.object.to-string.js");
var _interopRequireWildcard2 = _interopRequireDefault(require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireWildcard.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _layout = _interopRequireDefault(require("@/layout"));
var _new = _interopRequireDefault(require("@/layout/new"));
_vue.default.use(_vueRouter.default);

/* Layout */

var constantRoutes = [{
  path: "/redirect",
  component: _layout.default,
  hidden: true,
  children: [{
    path: "/redirect/:path*",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/redirect/index"));
      });
    }
  }]
}, {
  path: "/forgetPassword",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/forgetPassword/index"));
    });
  },
  hidden: true
}, {
  path: "/changePassword",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/changePassword/index"));
    });
  },
  hidden: true
}, {
  path: "/login",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/index"));
    });
  },
  hidden: true
}, {
  path: "/auth-redirect",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/login/auth-redirect"));
    });
  },
  hidden: true
}, {
  path: "/404",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/404"));
    });
  },
  hidden: true
}, {
  path: "/401",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/error-page/401"));
    });
  },
  hidden: true
}, {
  path: "/forbidden",
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require("@/views/forbidden"));
    });
  },
  hidden: true
}
// {
//   path: "/",
//   component: Layout,
//   redirect: "/employee"
// },
];
exports.constantRoutes = constantRoutes;
var asyncRoutes = [{
  path: '/',
  meta: {
    roles: [44]
  },
  redirect: '/dashboard',
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  meta: {
    roles: [44]
  },
  children: [{
    path: '/dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'Dashboard',
      noCache: true,
      icon: "dashboard",
      roles: [44]
    }
  }]
}, {
  path: '/preprojectplanmanagement',
  component: _layout.default,
  meta: {
    title: 'Milestone',
    icon: "plan",
    roles: [45, 46, 47, 48, 49]
  },
  children: [{
    path: '/preprojectplan',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/milestone/project-Pre-Plan'));
      });
    },
    name: 'Project Pre-Plan',
    meta: {
      title: 'Account/Project Creation',
      noCache: true,
      roles: [45]
    }
  }, {
    path: '/milestone',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/milestone'));
      });
    },
    name: 'Milestone',
    meta: {
      title: 'Project Milestone',
      noCache: true,
      roles: [46]
    }
  }, {
    path: '/milestonestatus',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/milestone/milestonestatus'));
      });
    },
    name: 'MilestoneStatus',
    meta: {
      title: 'Milestone Status',
      noCache: true,
      roles: [48]
    }
  }, {
    path: '/orderbook',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/milestone/orderbook'));
      });
    },
    name: 'Orderbook',
    meta: {
      title: 'Order Book',
      noCache: true,
      roles: [47]
    }
  }, {
    path: '/paymentcollection',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/milestone/payment-collection'));
      });
    },
    name: 'Paymentcollection',
    meta: {
      title: 'Payment Collection',
      noCache: true,
      roles: [54]
    }
  }, {
    path: '/accountvspontential',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/milestone/accountvspontential'));
      });
    },
    name: 'AccountVsPontential',
    meta: {
      title: 'Account Vs Pontential',
      noCache: true,
      roles: [49]
    }
  }]
}, {
  path: "/projectmanagement",
  component: _layout.default,
  meta: {
    title: "Project",
    icon: "consent",
    roles: [13]
  },
  children: [{
    path: "/project",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/project"));
      });
    },
    name: "Project",
    meta: {
      title: "Project",
      noCache: true,
      roles: [13]
    }
  }, {
    // path: "/projects/:id&:name&:milestoneId&:milestoneName",
    path: "/projects/:id&:name",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/projects"));
      });
    },
    name: "projects",
    props: true,
    hidden: true
  }]
}, {
  path: '/resourcemanagement',
  component: _layout.default,
  meta: {
    title: 'Resource',
    icon: "resume",
    roles: [14, 15, 16, 43, 51]
  },
  children: [{
    path: '/resourceRequestManagement',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/resources/resourceRequestManagement'));
      });
    },
    name: 'ResourceRequestManagement',
    meta: {
      title: 'Request Status',
      noCache: true,
      roles: [14]
    }
  }, {
    path: '/freeresourcelist',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/resources/freeResourceList'));
      });
    },
    name: 'FreeResourceList',
    meta: {
      title: 'Free Resource',
      noCache: true,
      roles: [51]
    }
  }, {
    path: '/freeresource',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/resources/freeResource'));
      });
    },
    name: 'FreeResource',
    meta: {
      title: 'Free Resource(15 days)',
      noCache: true,
      roles: [15]
    }
  }, {
    path: '/totalfree',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/resources/totalFree'));
      });
    },
    name: 'TotalFree',
    meta: {
      title: 'Free Resource Total',
      noCache: true,
      roles: [43]
    }
  }, {
    path: '/mappedresource',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/resources/mappedResource'));
      });
    },
    name: 'MappedResource',
    meta: {
      title: 'Mapped Resource',
      noCache: true,
      roles: [16]
    }
  }]
}, {
  path: '/employeemanagement',
  component: _layout.default,
  meta: {
    roles: [6]
  },
  children: [{
    path: '/employee',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/employee'));
      });
    },
    name: 'Employee',
    meta: {
      title: 'Employee',
      noCache: true,
      icon: "group",
      roles: [6]
    }
  }]
}, {
  path: '/salarymanagement',
  component: _layout.default,
  meta: {
    title: 'Salary',
    icon: "rupee-indian",
    roles: [9, 10, 42]
  },
  children: [{
    path: '/salary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/salary'));
      });
    },
    name: 'Salary',
    meta: {
      title: 'Salary',
      noCache: true,
      roles: [9]
    }
  }, {
    path: '/monthlysalary',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/salary/monthlySalary'));
      });
    },
    name: 'MonthlySalary',
    meta: {
      title: 'Monthly Salary List',
      noCache: true,
      roles: [10]
    }
  }, {
    path: '/fixedCost',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/salary/fixedCost'));
      });
    },
    name: 'fixedCost',
    meta: {
      title: 'Fixed Cost',
      noCache: true,
      roles: [42]
    }
  }, {
    path: '/payRoll',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/salary/payRoll'));
      });
    },
    name: 'PayRoll',
    meta: {
      title: 'Pay Roll',
      noCache: true,
      roles: [53]
    }
  }]
}, {
  path: "/report",
  component: _layout.default,
  meta: {
    title: "Reports",
    icon: "chart",
    roles: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 52, 30, 31, 32, 55, 56]
  },
  children: [{
    path: "/hrreport",
    component: _new.default,
    meta: {
      title: "HR Report",
      noCache: true,
      roles: [17, 18, 19, 20, 21]
    },
    children: [{
      path: '/employeecount',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/employeecount'));
        });
      },
      name: 'Employee count',
      meta: {
        title: 'Employee Count',
        noCache: true,
        roles: [17]
      }
    }, {
      path: '/newjoinee',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/newjoinee'));
        });
      },
      name: 'New joinee',
      meta: {
        title: 'New Joinee',
        noCache: true,
        roles: [18]
      }
    }, {
      path: "/experience",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/report/experience"));
        });
      },
      name: "Experience",
      meta: {
        title: "Experience",
        noCache: true,
        roles: [19]
      }
    }, {
      path: "/technology",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/report/technology"));
        });
      },
      name: "Tech",
      meta: {
        title: "Technology",
        noCache: true,
        roles: [20]
      }
    }, {
      path: "/teamstructure",
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require("@/views/report/teamstructure"));
        });
      },
      name: "Team Structure",
      meta: {
        title: "Team Structure",
        noCache: true,
        roles: [21]
      }
    }]
  }, {
    path: "/salaryreport",
    component: _new.default,
    meta: {
      title: "Salary Report",
      noCache: true,
      roles: [22, 23, 24, 25]
    },
    children: [{
      path: '/benchvsactual',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/benchMarkVsActualReport'));
        });
      },
      name: 'Bench Mark Vs Actual Report',
      meta: {
        title: 'Bench Mark Vs Actual',
        noCache: true,
        roles: [22]
      }
    }, {
      path: '/currentmonthsalary',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/salary'));
        });
      },
      name: 'Report',
      meta: {
        title: 'Current Month Salary',
        noCache: true,
        roles: [23]
      }
    }, {
      path: '/financialsalary',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/financialsalary'));
        });
      },
      name: 'Bench Mark Vs Actual Report',
      meta: {
        title: 'Financial Salary',
        noCache: true,
        roles: [24]
      }
    }, {
      path: '/CTCreport',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/CTCreport'));
        });
      },
      name: 'CTC Report',
      meta: {
        title: 'CTC Report',
        noCache: true,
        roles: [25]
      }
    }]
  }, {
    path: "/pmreport",
    component: _new.default,
    meta: {
      title: "PM Report",
      noCache: true,
      roles: [55, 26, 27, 28, 29, 52]
    },
    children: [{
      path: '/freeresourcereport',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/freeResourceReport'));
        });
      },
      name: 'Free',
      meta: {
        title: 'Free resource',
        noCache: true,
        roles: [55]
      }
    }, {
      path: '/free',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/free'));
        });
      },
      name: 'Free',
      meta: {
        title: 'Free resource(15 days)',
        noCache: true,
        roles: [26]
      }
    }, {
      path: '/resourceallocation',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/pending'));
        });
      },
      name: 'Mapped',
      meta: {
        title: 'Resource Allocation',
        noCache: true,
        roles: [27]
      }
    }, {
      path: '/projectmapped',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/mapped'));
        });
      },
      name: 'Mapped',
      meta: {
        title: 'Project Mapped',
        noCache: true,
        roles: [28]
      }
    }, {
      path: '/pcr',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/pcr'));
        });
      },
      name: 'PCR',
      meta: {
        title: 'PCR',
        noCache: true,
        roles: [29]
      }
    }, {
      path: '/resourcereleasedate',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resourcereleasedate'));
        });
      },
      name: 'resourcereleasedate',
      meta: {
        title: 'Resource Release',
        noCache: true,
        roles: [52]
      }
    }]
  }, {
    path: "/managementreport",
    component: _new.default,
    meta: {
      title: "Management Report",
      noCache: true,
      roles: [30, 31, 32, 56]
    },
    children: [{
      path: '/projectpl',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/projectPandL'));
        });
      },
      name: 'UnUtilized',
      meta: {
        title: 'Project P&L',
        noCache: true,
        roles: [30]
      }
    }, {
      path: '/efficiency',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/efficiency'));
        });
      },
      name: 'Efficiency',
      meta: {
        title: 'Employee P&L',
        noCache: true,
        roles: [31]
      }
    }, {
      path: '/unutilized',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/unutilized'));
        });
      },
      name: 'UnUtilized',
      meta: {
        title: 'Employee UnUtilized',
        noCache: true,
        roles: [32]
      }
    }, {
      path: '/employeeReports',
      component: function component() {
        return Promise.resolve().then(function () {
          return (0, _interopRequireWildcard2.default)(require('@/views/report/resource/employeeReports'));
        });
      },
      name: 'employeeReports',
      meta: {
        title: 'Employee Reports',
        noCache: true,
        roles: [56]
      }
    }]
  }]
}, {
  path: "/master",
  component: _layout.default,
  meta: {
    title: "Settings",
    icon: "gear",
    noCache: true,
    roles: [33, 34, 35]
  },
  children: [{
    path: "/masterLabel",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/master-label"));
      });
    },
    name: "MasterLabel",
    meta: {
      title: "Master",
      noCache: true,
      roles: [33]
    }
  }, {
    path: "/masterRole",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/master-role"));
      });
    },
    name: "MasterRole",
    meta: {
      title: "Role Permission",
      noCache: true,
      roles: [34]
    }
  }, {
    path: "/masterCTC",
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require("@/views/master-CTC"));
      });
    },
    name: "Master CTC",
    meta: {
      title: "Compensation Bench Mark",
      noCache: true,
      roles: [35]
    }
  }
  // {
  //   path: "/masterScreens",
  //   component: () => import("@/views/master-screens"),
  //   name: "Master Screens",
  //   meta: { title: "Master Screens", noCache: true }
  // }
  ]
},
// 404 page must be placed at the end !!!

{
  path: '*',
  meta: {
    roles: ["forbidden"]
  },
  redirect: "/forbidden",
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    mode: "history",
    // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;