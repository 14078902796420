var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _vm.device === "mobile" && _vm.sidebar.opened
      ? _c("div", {
          staticClass: "drawer-bg",
          on: { click: _vm.handleClickOutside },
        })
      : _vm._e(),
    _c("div", [_c("app-main")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }