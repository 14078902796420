var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "5px",
            "padding-left": "10px",
            "padding-top": "5px",
            "padding-right": "10px",
          },
        },
        [
          _c("el-input", {
            staticStyle: { width: "20%", float: "right" },
            attrs: {
              disabled: _vm.DisabledOnLoad,
              size: "small",
              placeholder: "Type and press enter to search",
            },
            on: {
              input: function ($event) {
                return _vm.getCount(_vm.search)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchByName()
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          _c(
            "el-form",
            {
              staticStyle: { width: "15%", float: "left" },
              attrs: { model: _vm.dropdown },
            },
            [
              _c(
                "el-form-item",
                { attrs: { size: "small" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Please select" },
                      on: {
                        change: function ($event) {
                          return _vm.Status()
                        },
                      },
                      model: {
                        value: _vm.dropdown.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.dropdown, "status", $$v)
                        },
                        expression: "dropdown.status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "Pending", value: "Pending" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Approved", value: "Approved" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Completed", value: "Completed" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Released", value: "Released" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Deleted", value: "Deleted" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Rejected", value: "Rejected" },
                      }),
                      _c("el-option", {
                        attrs: { label: "Cancelled", value: "Cancelled" },
                      }),
                      _c("el-option", {
                        attrs: { label: "All", value: "All" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "resource-request",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "default-sort": { prop: "projectName", order: "ascending" },
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            height: "560",
          },
        },
        [
          _c("el-table-column", {
            key: "1",
            attrs: {
              width: "220",
              label: "Project Name",
              sortable: "",
              prop: "projectName",
            },
          }),
          this.rejectEmployee
            ? _c("el-table-column", {
                key: "2",
                attrs: {
                  width: "200",
                  label: "Employee Name",
                  sortable: "",
                  prop: "employeeName",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            key: "3",
            attrs: {
              width: "180",
              sortable: "",
              label: "Skill",
              prop: "skill",
            },
          }),
          _c("el-table-column", {
            key: "4",
            attrs: { label: "Request Type", width: "160", prop: "requestType" },
          }),
          _c("el-table-column", {
            key: "5",
            attrs: { align: "center", width: "115", label: "Start Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.startDate))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            key: "6",
            attrs: { align: "center", width: "115", label: "End Date" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.endDate))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          this.deleteActualEndDate
            ? _c("el-table-column", {
                key: "7",
                attrs: {
                  align: "center",
                  width: "110",
                  label: "Actual End Date",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm._f("formatDate")(scope.row.actualEndDate)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3571503037
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            key: "8",
            attrs: {
              align: "center",
              width: "130",
              sortable: "",
              label: "Hours (Day)",
              prop: "hoursPerDay",
            },
          }),
          _c("el-table-column", {
            key: "9",
            attrs: {
              align: "center",
              width: "140",
              sortable: "",
              label: "Status",
              prop: "requestStatus",
            },
          }),
          _c("el-table-column", {
            key: "10",
            attrs: { width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.requestStatus === "Approved" &&
                    scope.row.checkApprovedDate == false
                      ? [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Complete",
                                placement: "top-start",
                              },
                            },
                            [
                              _vm.Complete == true
                                ? _c("i", {
                                    staticClass: "el-icon-success",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleComplete(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Release",
                                placement: "top-start",
                              },
                            },
                            [
                              _c("svg-icon", {
                                staticClass: "release",
                                staticStyle: {
                                  color: "orange",
                                  padding: "5px",
                                  "font-size": "25px",
                                },
                                attrs: {
                                  className: "release",
                                  iconClass: "release",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleRelease(scope.row)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                    scope.row.requestStatus === "Approved" &&
                    scope.row.checkApprovedDate == true
                      ? [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Delete",
                                placement: "top-start",
                              },
                            },
                            [
                              _vm.Delete == true
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApproveDelete(
                                          scope.row
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : scope.row.requestStatus === "Rejected" ||
                        scope.row.requestStatus === "Completed" ||
                        scope.row.requestStatus === "Cancelled" ||
                        scope.row.requestStatus === "Deleted" ||
                        scope.row.requestStatus === "Released"
                      ? void 0
                      : scope.row.requestStatus !== "Approved" &&
                        scope.row.requestStatus !== "Completed"
                      ? [
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Approve",
                                placement: "top-start",
                              },
                            },
                            [
                              _vm.Approve == true
                                ? _c("i", {
                                    staticClass: "el-icon-check",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleApprove(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Cancel",
                                placement: "top-start",
                              },
                            },
                            [
                              _vm.Cancel == true
                                ? _c("i", {
                                    staticClass: "el-icon-delete",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDelete(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Reject",
                                placement: "top-start",
                              },
                            },
                            [
                              _vm.Reject == true
                                ? _c("i", {
                                    staticClass: "el-icon-close",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleReject(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "Edit",
                                placement: "top-start",
                              },
                            },
                            [
                              _vm.Edit == true
                                ? _c("i", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: {
                                      color: "orange",
                                      padding: "5px",
                                      "font-size": "20px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEdit(scope.row)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp2.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp2, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.Status()
          },
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Approve Resource Request",
            "close-on-click-modal": false,
            width: "40%",
            visible: _vm.approveDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.approveDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm1",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules2,
                model: _vm.temp,
                "label-width": "50%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "requesterComment",
                    label: "Requester Comment",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.temp.requesterComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "requesterComment", $$v)
                      },
                      expression: "temp.requesterComment",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox-group",
                    {
                      on: {
                        change: function ($event) {
                          return _vm.ExtraEffort()
                        },
                      },
                      model: {
                        value: _vm.temp.check,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "check", $$v)
                        },
                        expression: "temp.check",
                      },
                    },
                    [
                      _c("el-checkbox", {
                        attrs: { label: "Extra Effort", name: "type" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "employeeId", label: "Employee" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { filterable: "", placeholder: "Please select" },
                      on: {
                        change: function ($event) {
                          return _vm.EmployeeSalary(_vm.temp.employeeId)
                        },
                      },
                      model: {
                        value: _vm.temp.employeeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "employeeId", $$v)
                        },
                        expression: "temp.employeeId",
                      },
                    },
                    _vm._l(_vm.employee, function (item) {
                      return _c("el-option", {
                        key: item.employeeId,
                        attrs: {
                          label: item.employeeName,
                          value: item.employeeId,
                        },
                      })
                    }),
                    1
                  ),
                  _c("div", {
                    staticClass: "el-form-item__error",
                    attrs: { id: "hour" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Cost Per Hour ",
                    prop: "perHourEmployeeCost",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: this.temp.perHourEmployeeCost,
                      callback: function ($$v) {
                        _vm.$set(this.temp, "perHourEmployeeCost", $$v)
                      },
                      expression: "this.temp.perHourEmployeeCost",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "hoursPerDay", label: "Hours Per Day" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.temp.hoursPerDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "hoursPerDay", _vm._n($$v))
                      },
                      expression: "temp.hoursPerDay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { prop: "approverComment", label: "Approver Comment" },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.approverComment,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "approverComment", $$v)
                      },
                      expression: "temp.approverComment",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.approveDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.DisabledIs, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.ApproveData()
                    },
                  },
                },
                [_vm._v("Approve")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            title: "Reject Resource Request",
            visible: _vm.rejectDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.rejectDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm2",
              staticStyle: { width: "80%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules3,
                model: _vm.temp,
                "label-width": "50%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "Reason For Rejecting" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "reason", $$v)
                      },
                      expression: "temp.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.rejectDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.IsDisable },
                  on: {
                    click: function ($event) {
                      return _vm.RejectData()
                    },
                  },
                },
                [_vm._v("Reject")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "40%",
            "close-on-click-modal": false,
            title: "Edit Resource Request",
            visible: _vm.editDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm4",
              staticStyle: { width: "80%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules5,
                model: _vm.temp,
                "label-width": "50%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Start Date", prop: "startDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "startDate", $$v)
                      },
                      expression: "temp.startDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date", prop: "endDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "endDate", $$v)
                      },
                      expression: "temp.endDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "hoursPerDay", label: "Hours Per Day" } },
                [
                  _c("el-slider", {
                    attrs: { "show-stops": "", min: 1, max: 8 },
                    model: {
                      value: _vm.temp.hoursPerDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp, "hoursPerDay", $$v)
                      },
                      expression: "temp.hoursPerDay",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "requestTypeId", label: "Request Type" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "filter-item",
                      attrs: { placeholder: "Please select" },
                      model: {
                        value: _vm.temp.requestTypeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.temp, "requestTypeId", $$v)
                        },
                        expression: "temp.requestTypeId",
                      },
                    },
                    _vm._l(_vm.requesttypes, function (item) {
                      return _c("el-option", {
                        key: item.labelId,
                        attrs: { label: item.labelName, value: item.labelId },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.editDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", disabled: _vm.EditDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.EditData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35%",
            "close-on-click-modal": false,
            title: "Complete Resource Request",
            visible: _vm.completeDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.completeDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm3",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules1,
                model: _vm.temp1,
                "label-width": "45%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Start Date", prop: "actualStartDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.temp1.actualStartDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualStartDate", $$v)
                      },
                      expression: "temp1.actualStartDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date", prop: "actualEndDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp1.actualEndDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualEndDate", $$v)
                      },
                      expression: "temp1.actualEndDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "Reason" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp1.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "reason", $$v)
                      },
                      expression: "temp1.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.completeDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.CompleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.CompleteData()
                    },
                  },
                },
                [_vm._v("Complete")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "35%",
            title: "Release Resource Request",
            "close-on-click-modal": false,
            visible: _vm.releaseDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.releaseDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm5",
              staticStyle: { width: "75%", "margin-left": "8%" },
              attrs: {
                rules: _vm.rules1,
                model: _vm.temp1,
                "label-width": "45%",
                "label-position": "left",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "Start Date", prop: "actualStartDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      disabled: "",
                    },
                    model: {
                      value: _vm.temp1.actualStartDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualStartDate", $$v)
                      },
                      expression: "temp1.actualStartDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "End Date", prop: "actualEndDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      placeholder: "Please pick a date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.temp1.actualEndDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "actualEndDate", $$v)
                      },
                      expression: "temp1.actualEndDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "Reason" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.temp1.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.temp1, "reason", $$v)
                      },
                      expression: "temp1.reason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.releaseDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.ReleaseDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.ReleaseData()
                    },
                  },
                },
                [_vm._v("Release")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteResourceRequestDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteResourceRequestDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the Resource Request")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteResourceRequestDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.DeleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteApprovedDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteApprovedDialog = $event
            },
          },
        },
        [
          _c("span", [
            _vm._v("Please confirm to delete the Approved Resource Request"),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteApprovedDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.DeleteApprovedDisabled,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.DeleteApprovedData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }