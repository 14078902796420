var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "8px",
            "padding-left": "10px",
            "padding-top": "5px",
            "padding-right": "10px",
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                disabled: _vm.DisabledOnLoad,
                size: "small",
                placeholder: "Please select",
              },
              on: { change: _vm.HandleAccountDropdown },
              model: {
                value: _vm.dropdown.accountId,
                callback: function ($$v) {
                  _vm.$set(_vm.dropdown, "accountId", $$v)
                },
                expression: "dropdown.accountId",
              },
            },
            _vm._l(_vm.accounts, function (item) {
              return _c("el-option", {
                key: item.accountId,
                attrs: { label: item.accountName, value: item.accountId },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              attrs: {
                filterable: "",
                disabled: _vm.DisabledOnLoad,
                size: "small",
                placeholder: "Please select",
              },
              on: {
                change: function ($event) {
                  return _vm.getMilestone()
                },
              },
              model: {
                value: _vm.dropdown.prePlanProjectId,
                callback: function ($$v) {
                  _vm.$set(_vm.dropdown, "prePlanProjectId", $$v)
                },
                expression: "dropdown.prePlanProjectId",
              },
            },
            _vm._l(_vm.preProjectNames1, function (item) {
              return _c("el-option", {
                key: item.prePlanProjectId,
                attrs: {
                  label: item.prePlanProject,
                  value: item.prePlanProjectId,
                },
              })
            }),
            1
          ),
          _c("el-input", {
            staticStyle: { width: "20%" },
            attrs: {
              disabled: _vm.DisabledOnLoad,
              size: "small",
              placeholder: "Type and press enter to search",
            },
            on: {
              input: function ($event) {
                return _vm.getCount(_vm.search)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchByName()
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
          this.canCreate == true
            ? _c("i", {
                staticClass: "el-icon-circle-plus",
                staticStyle: {
                  float: "right",
                  color: "orange",
                  "font-size": "30px",
                },
                on: {
                  click: function ($event) {
                    return _vm.handleCreate()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "cardList2" }, [
        _c("div", { staticClass: "cardInner2" }, [
          _c("label", [_vm._v(" " + _vm._s(this.milestoneCount) + " ")]),
          _c("span", [_vm._v(" Total Milestones ")]),
        ]),
        _c("div", { staticClass: "cardInner2 lastCart2" }, [
          _c("label", [_vm._v(" " + _vm._s(this.totalBCYAmount) + " ")]),
          _c("span", [_vm._v(" Total BCY Amount ")]),
        ]),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            height: "570",
          },
        },
        [
          this.canEdit == true
            ? _c("el-table-column", {
                attrs: { width: "100", align: "center", fixed: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-edit",
                            staticStyle: {
                              "padding-right": "5px",
                              color: "orange",
                              "font-size": "20px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate(scope.row)
                              },
                            },
                          }),
                          _c("i", {
                            staticClass: "el-icon-delete",
                            staticStyle: {
                              "padding-right": "5px",
                              color: "orange",
                              "font-size": "20px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelete(scope.row)
                              },
                            },
                          }),
                          _c("svg-icon", {
                            staticClass: "duplicate",
                            staticStyle: {
                              color: "orange",
                              "font-size": "16px",
                            },
                            attrs: {
                              className: "duplicate",
                              iconClass: "duplicate",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleUpdate1(scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3868684480
                ),
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: {
              width: "105px",
              fixed: "",
              sortable: "",
              label: "Account",
              prop: "accountName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              fixed: "",
              sortable: "",
              label: "Project",
              prop: "prePlanProjectName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              fixed: "",
              sortable: "",
              label: "Milestone",
              prop: "milestone",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "170px",
              sortable: "",
              label: "Milestone Status",
              prop: "milestoneStatus",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              sortable: "",
              label: "Project Owner",
              prop: "projectOwnerName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              sortable: "",
              label: "Technical Lead",
              prop: "technicalOwnerName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              sortable: "",
              label: "Project Type",
              prop: "projectType",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: "Planned Start Date",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("formatDate")(scope.row.startDate))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Planned End Date", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.plannedEndDate))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { align: "center", label: "Actual End Date", width: "160" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDate")(scope.row.actualEndDate))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "140px",
              label: "FCY Amount",
              prop: "fcyAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "140px",
              label: "Currency Type",
              prop: "currencyType",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "140px",
              label: "BCY Amount",
              prop: "bcyAmount",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "140px",
              label: "BCY Value",
              prop: "bcyValue",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              label: "Payment Probability",
              prop: "paymentProbability",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "Payment Planned Date", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(scope.row.paymentPlannedDate)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "Payment Expected Date", width: "200" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatDate")(scope.row.paymentExpectedDate)
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              width: "140px",
              label: "Expected month",
              prop: "expectedMonth",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "140px",
              label: "Expected Date",
              prop: "expectedWeek",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              label: "Meeting Comments",
              prop: "meetingComments",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total > 0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp1.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp1, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getMilestone()
          },
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "milestone",
          attrs: {
            width: "90%",
            title: _vm.textMap[_vm.dialogStatus],
            "close-on-click-modal": false,
            visible: _vm.dialogFormVisible,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogFormVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "90%", "margin-left": "5%" },
              attrs: {
                rules: _vm.rules,
                model: _vm.addMilstone,
                "label-width": "45%",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { prop: "accountId", label: "Account/Client" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "240px" },
                              attrs: {
                                filterable: "",
                                placeholder: "Please select",
                              },
                              on: { change: _vm.HandleAccountPreplan },
                              model: {
                                value: _vm.addMilstone.accountId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.addMilstone, "accountId", $$v)
                                },
                                expression: "addMilstone.accountId",
                              },
                            },
                            _vm._l(_vm.accounts, function (item) {
                              return _c("el-option", {
                                key: item.accountId,
                                attrs: {
                                  label: item.accountName,
                                  value: item.accountId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "Project Name",
                            prop: "prePlanProjectId",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "240px" },
                              attrs: {
                                filterable: "",
                                placeholder: "Please select",
                              },
                              model: {
                                value: _vm.addMilstone.prePlanProjectId,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addMilstone,
                                    "prePlanProjectId",
                                    $$v
                                  )
                                },
                                expression: "addMilstone.prePlanProjectId",
                              },
                            },
                            _vm._l(_vm.preProjectNames, function (item) {
                              return _c("el-option", {
                                key: item.prePlanProjectId,
                                attrs: {
                                  label: item.prePlanProject,
                                  value: item.prePlanProjectId,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tabs",
                {
                  staticClass: "demo-tabs",
                  model: {
                    value: _vm.activeName,
                    callback: function ($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName",
                  },
                },
                [
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Milestone Info", name: "first" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "projectTypeId",
                                    label: "Project Type",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Please select",
                                      },
                                      model: {
                                        value: _vm.addMilstone.projectTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addMilstone,
                                            "projectTypeId",
                                            $$v
                                          )
                                        },
                                        expression: "addMilstone.projectTypeId",
                                      },
                                    },
                                    _vm._l(_vm.projecttypes, function (item) {
                                      return _c("el-option", {
                                        key: item.labelId,
                                        attrs: {
                                          label: item.labelName,
                                          value: item.labelId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "milestoneStatusId",
                                    label: "Milestone Status",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Please select",
                                      },
                                      on: { change: _vm.HandleMilestoneStatus },
                                      model: {
                                        value:
                                          _vm.addMilstone.milestoneStatusId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addMilstone,
                                            "milestoneStatusId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addMilstone.milestoneStatusId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.milestonestatus,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.labelId,
                                          attrs: {
                                            label: item.labelName,
                                            value: item.labelId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "milestone",
                                    label: "Milestone",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    model: {
                                      value: _vm.addMilstone.milestone,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "milestone",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.milestone",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "currencyTypeId",
                                    label: "Currency Type",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Please select",
                                      },
                                      model: {
                                        value: _vm.addMilstone.currencyTypeId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addMilstone,
                                            "currencyTypeId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addMilstone.currencyTypeId",
                                      },
                                    },
                                    _vm._l(_vm.currencyTypes, function (item) {
                                      return _c("el-option", {
                                        key: item.labelId,
                                        attrs: {
                                          label: item.labelName,
                                          value: item.labelId,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.addMilstone.projectTypeId != 290
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "FCY Amount",
                                            prop: "fcyAmount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "240px" },
                                            attrs: {
                                              placeholder: "FCY Amount",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.HandleFCY()
                                              },
                                            },
                                            model: {
                                              value: _vm.addMilstone.fcyAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addMilstone,
                                                  "fcyAmount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "addMilstone.fcyAmount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "BCY Value",
                                            prop: "bcyValue",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "240px" },
                                            attrs: {
                                              id: "BCYValue",
                                              placeholder: "BCY Value",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.HandleBCYValue()
                                              },
                                            },
                                            model: {
                                              value: _vm.addMilstone.bcyValue,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addMilstone,
                                                  "bcyValue",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "addMilstone.bcyValue",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 6 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "BCY Amount",
                                            prop: "bcyAmount",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "240px" },
                                            attrs: {
                                              id: "BCYAmount",
                                              placeholder: "BCY Amount",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.HandleBCY()
                                              },
                                            },
                                            model: {
                                              value: _vm.addMilstone.bcyAmount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.addMilstone,
                                                  "bcyAmount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "addMilstone.bcyAmount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.addMilstone.projectTypeId != 290
                                    ? _c(
                                        "el-col",
                                        { attrs: { span: 6 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticClass: "calculate",
                                                  attrs: {
                                                    disabled: !(
                                                      _vm.addMilstone
                                                        .fcyAmount != 0
                                                    ),
                                                  },
                                                  on: {
                                                    click:
                                                      _vm.HandleCalculations,
                                                  },
                                                },
                                                [_vm._v("Calculate")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-tab-pane",
                    { attrs: { label: "Others", name: "second" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "technicalOwnerId",
                                    label: "Technical Lead",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Please select",
                                      },
                                      model: {
                                        value: _vm.addMilstone.technicalOwnerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addMilstone,
                                            "technicalOwnerId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addMilstone.technicalOwnerId",
                                      },
                                    },
                                    _vm._l(_vm.owners, function (item) {
                                      return _c("el-option", {
                                        key: item.employeeID,
                                        attrs: {
                                          label: item.employeeName,
                                          value: item.employeeID,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "projectOwnerId",
                                    label: "Project Manager",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        filterable: "",
                                        placeholder: "Please select",
                                      },
                                      model: {
                                        value: _vm.addMilstone.projectOwnerId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addMilstone,
                                            "projectOwnerId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addMilstone.projectOwnerId",
                                      },
                                    },
                                    _vm._l(_vm.owners, function (item) {
                                      return _c("el-option", {
                                        key: item.employeeID,
                                        attrs: {
                                          label: item.employeeName,
                                          value: item.employeeID,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Planned Start Date",
                                    prop: "startDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "Please pick a date",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.addMilstone.startDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "startDate",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.startDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Planned End Date",
                                    prop: "plannedEndDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "Please pick a date",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.addMilstone.plannedEndDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "plannedEndDate",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.plannedEndDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Actual End Date",
                                    prop: "actualEndDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      type: "date",
                                      "addMilstone.skillId": "",
                                      placeholder: "Please pick a date",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.addMilstone.actualEndDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "actualEndDate",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.actualEndDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  ref: "paymentProbability",
                                  attrs: {
                                    prop: "paymentProbabilityId",
                                    label: "Payment Probability",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "240px" },
                                      attrs: {
                                        clearable: "",
                                        filterable: "",
                                        placeholder: "Please select",
                                      },
                                      on: {
                                        change: _vm.HandlePaymentProbablity,
                                      },
                                      model: {
                                        value:
                                          _vm.addMilstone.paymentProbabilityId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.addMilstone,
                                            "paymentProbabilityId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "addMilstone.paymentProbabilityId",
                                      },
                                    },
                                    _vm._l(
                                      _vm.milestonepaymentstatus,
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.labelId,
                                          attrs: {
                                            label: item.labelName,
                                            value: item.labelId,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "Payment Planned Date",
                                    prop: "paymentPlannedDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "Please pick a date",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    model: {
                                      value: _vm.addMilstone.paymentPlannedDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "paymentPlannedDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addMilstone.paymentPlannedDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  ref: "paymentExpectedDate",
                                  attrs: {
                                    label: "Payment Expected Date",
                                    prop: "paymentExpectedDate",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      type: "date",
                                      placeholder: "Please pick a date",
                                      format: "dd-MM-yyyy",
                                      "value-format": "yyyy-MM-dd",
                                    },
                                    on: {
                                      change: _vm.HandlePaymentExpectedDate,
                                    },
                                    model: {
                                      value:
                                        _vm.addMilstone.paymentExpectedDate,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "paymentExpectedDate",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "addMilstone.paymentExpectedDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "expectedMonth",
                                    label: "Expected Month",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    model: {
                                      value: _vm.addMilstone.expectedMonth,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "expectedMonth",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.expectedMonth",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "expectedWeek",
                                    label: "Expected Date",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    model: {
                                      value: _vm.addMilstone.expectedWeek,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "expectedWeek",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.expectedWeek",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "meetingComments",
                                    label: "Meeting Comments",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    model: {
                                      value: _vm.addMilstone.meetingComments,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "meetingComments",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.meetingComments",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.addMilstone.projectTypeId == 290
                ? _c("el-divider")
                : _vm._e(),
              _vm.addMilstone.projectTypeId == 290
                ? _c(
                    "div",
                    [
                      _c(
                        "div",
                        { staticClass: "flx" },
                        [
                          _c("el-checkbox", {
                            attrs: { label: "SubMilestone", size: "large" },
                            on: {
                              change: function ($event) {
                                return _vm.resetBcy()
                              },
                            },
                            model: {
                              value: _vm.checked,
                              callback: function ($$v) {
                                _vm.checked = $$v
                              },
                              expression: "checked",
                            },
                          }),
                          _vm.checked == true
                            ? _c("i", {
                                staticClass: "el-icon-circle-plus",
                                staticStyle: {
                                  color: "orange",
                                  "font-size": "20px",
                                  "padding-top": "20px",
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.dialogStatus === "create"
                                      ? _vm.addRow("create")
                                      : _vm.addRow("edit")
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm.checked == true
                        ? _c(
                            "el-table",
                            {
                              staticStyle: { width: "500%" },
                              attrs: {
                                data: _vm.addMilstone.milestone_Skill_Lists,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  label: "Skill Name",
                                  prop: "skillName",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "scope.row.skill_Id",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "filter-item",
                                                  staticStyle: {
                                                    width: "140px",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder:
                                                      "Please select",
                                                  },
                                                  model: {
                                                    value: scope.row.skill_Id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "skill_Id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.skill_Id",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.skills,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.skillId,
                                                      attrs: {
                                                        label: item.skill,
                                                        value: item.skillId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3523197507
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Skill Level",
                                  prop: "skillLevelId",
                                  width: "150",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "scope.row.skillLevelId",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "filter-item",
                                                  staticStyle: {
                                                    width: "140px",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder:
                                                      "Please select",
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.skillLevelId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "skillLevelId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.skillLevelId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.skillLevels,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.skillLevelId,
                                                      attrs: {
                                                        label: item.skillLevel,
                                                        value:
                                                          item.skillLevelId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  369227299
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Start Date",
                                  prop: "start_Date",
                                  width: "140",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "start_Date" } },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "130px" },
                                                attrs: {
                                                  type: "date",
                                                  placeholder:
                                                    "Please pick a date",
                                                  format: "dd-MM-yyyy",
                                                  "value-format": "yyyy-MM-dd",
                                                },
                                                model: {
                                                  value: scope.row.start_Date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "start_Date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.start_Date",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2658630103
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "End Date",
                                  prop: "end_Date",
                                  width: "140",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "end_Date" } },
                                            [
                                              _c("el-date-picker", {
                                                staticStyle: { width: "130px" },
                                                attrs: {
                                                  type: "date",
                                                  placeholder:
                                                    "Please pick a date",
                                                  format: "dd-MM-yyyy",
                                                  "value-format": "yyyy-MM-dd",
                                                },
                                                model: {
                                                  value: scope.row.end_Date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "end_Date",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.end_Date",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3213751383
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Hours Per Day",
                                  prop: "hoursPerDay",
                                  width: "130",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "hoursPerDay" } },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "filter-item",
                                                  staticStyle: {
                                                    width: "120px",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    placeholder: "Total Hours",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.totalHour(
                                                        $event,
                                                        scope.$index,
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      scope.row.hoursPerDay,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        scope.row,
                                                        "hoursPerDay",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "scope.row.hoursPerDay",
                                                  },
                                                },
                                                [
                                                  _c("el-option", {
                                                    attrs: { value: 1 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 2 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 3 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 4 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 5 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 6 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 7 },
                                                  }),
                                                  _c("el-option", {
                                                    attrs: { value: 8 },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2045851712
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Total Hours",
                                  prop: "total_Hours",
                                  width: "110",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "total_Hours" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "90px" },
                                                attrs: {
                                                  disabled: "",
                                                  placeholder: "Total Hour",
                                                },
                                                model: {
                                                  value: scope.row.total_Hours,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "total_Hours",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.total_Hours",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  1477537239
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "REQ Commend",
                                  prop: "requester_Comment",
                                  width: "140",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "requester_Comment",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                staticStyle: { width: "130px" },
                                                attrs: {
                                                  placeholder: "commend",
                                                },
                                                model: {
                                                  value:
                                                    scope.row.requester_Comment,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "requester_Comment",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.requester_Comment",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  4059384107
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "Currency",
                                  prop: "currency_Type",
                                  width: "110",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                prop: "addMilstone.currencyTypeId",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  staticClass: "filter-item",
                                                  staticStyle: {
                                                    width: "90px",
                                                  },
                                                  attrs: {
                                                    filterable: "",
                                                    disabled: "",
                                                    placeholder:
                                                      "Please select",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.addMilstone
                                                        .currencyTypeId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.addMilstone,
                                                        "currencyTypeId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "addMilstone.currencyTypeId",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.currencyTypes,
                                                  function (item) {
                                                    return _c("el-option", {
                                                      key: item.labelId,
                                                      attrs: {
                                                        label: item.labelName,
                                                        value: item.labelId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3596139943
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "FCY Amount",
                                  prop: "fcY_Amount",
                                  width: "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "fcY_Amount" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  width: "100px",
                                                  "text-align": "right",
                                                },
                                                attrs: {
                                                  placeholder: "FCY Amount",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.SkillHandleFCY(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.fcY_Amount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "fcY_Amount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.fcY_Amount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  763122178
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "BCY Value",
                                  prop: "bcY_value",
                                  width: "100",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "bcY_value" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  width: "80px",
                                                  "text-align": "right",
                                                },
                                                attrs: {
                                                  id: "SkillBCYValue",
                                                  placeholder: "BCY Value",
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.SkillHandleBCYValue(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.bcY_value,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "bcY_value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.bcY_value",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2161024766
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  label: "BCY Amount",
                                  prop: "bcY_Amount",
                                  width: "120",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "el-form-item",
                                            { attrs: { prop: "bcY_Amount" } },
                                            [
                                              _c("el-input", {
                                                staticStyle: {
                                                  width: "90px",
                                                  "text-align": "right",
                                                },
                                                attrs: {
                                                  id: "SkillBCYAmount",
                                                  placeholder: "BCY Amount",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.SkillHandleBCYAmount(
                                                      scope.$index
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: scope.row.bcY_Amount,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      scope.row,
                                                      "bcY_Amount",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "scope.row.bcY_Amount",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3264236904
                                ),
                              }),
                              _c("el-table-column", {
                                staticStyle: { width: "90px" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c("el-form-item", [
                                            _c("div", { staticClass: "flx" }, [
                                              scope.row.fcY_Amount != 0
                                                ? _c("i", {
                                                    staticClass:
                                                      "el-icon-check",
                                                    staticStyle: {
                                                      color: "orange",
                                                      "font-size": "20px",
                                                      "margin-left": "6px",
                                                      "margin-top": "5px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.SkillHandleCalculations(
                                                          scope.$index
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _vm._e(),
                                              _c("i", {
                                                staticClass: "el-icon-delete",
                                                staticStyle: {
                                                  color: "orange",
                                                  "font-size": "20px",
                                                  "margin-left": "6px",
                                                  "margin-top": "5px",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.dialogStatus ===
                                                    "create"
                                                      ? _vm.deleteRow(
                                                          scope.$index
                                                        )
                                                      : _vm.handleSkillDelete(
                                                          scope.row,
                                                          scope.$index
                                                        )
                                                  },
                                                },
                                              }),
                                            ]),
                                          ]),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  3051933813
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("el-divider"),
                      _c(
                        "el-row",
                        [
                          _vm.checked == false &&
                          _vm.addMilstone.projectTypeId == 290
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "advanceAmt",
                                        label: "Advance Amount",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "240px" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.advanceAmt()
                                          },
                                        },
                                        model: {
                                          value: _vm.addMilstone.advanceAmt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addMilstone,
                                              "advanceAmt",
                                              $$v
                                            )
                                          },
                                          expression: "addMilstone.advanceAmt",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.checked == true &&
                          _vm.addMilstone.projectTypeId == 290
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        prop: "adjusmentAmt",
                                        label: "Adjustment Amount",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "240px" },
                                        on: {
                                          change: function ($event) {
                                            return _vm.AdjustmentAmt()
                                          },
                                        },
                                        model: {
                                          value: _vm.addMilstone.adjusmentAmt,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.addMilstone,
                                              "adjusmentAmt",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "addMilstone.adjusmentAmt",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "FCY Amount",
                                    prop: "fcyAmount",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      disabled: "",
                                      placeholder: "FCY Amount",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.HandleFCY()
                                      },
                                    },
                                    model: {
                                      value: _vm.addMilstone.fcyAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "fcyAmount",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.fcyAmount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "BCY Value",
                                    prop: "bcyValue",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      id: "BCYValue",
                                      disabled: "",
                                      placeholder: "BCY Value",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.HandleBCYValue()
                                      },
                                    },
                                    model: {
                                      value: _vm.addMilstone.bcyValue,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "bcyValue",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.bcyValue",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "BCY Amount",
                                    prop: "bcyAmount",
                                  },
                                },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "240px" },
                                    attrs: {
                                      id: "BCYAmount",
                                      disabled: "",
                                      placeholder: "BCY Amount",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.HandleBCY()
                                      },
                                    },
                                    model: {
                                      value: _vm.addMilstone.bcyAmount,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.addMilstone,
                                          "bcyAmount",
                                          $$v
                                        )
                                      },
                                      expression: "addMilstone.bcyAmount",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.addMilstone.projectTypeId != 290
                            ? _c(
                                "el-col",
                                { attrs: { span: 6 } },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _vm.addMilstone.fcyAmount != 0
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "calculate",
                                              on: {
                                                click: _vm.HandleCalculations,
                                              },
                                            },
                                            [_vm._v("Calculate")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("el-divider"),
          _vm.addMilstone.projectTypeId != 290
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isDisabled },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.createData()
                            : _vm.updateData()
                        },
                      },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.addMilstone.projectTypeId == 290
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.isDisabled },
                      on: {
                        click: function ($event) {
                          _vm.dialogStatus === "create"
                            ? _vm.createSkillData()
                            : _vm.updateSkillData()
                        },
                      },
                    },
                    [_vm._v("Confirm")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteMilestoneDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteMilestoneDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the Milestone!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteMilestoneDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isDeleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.deleteSkillDialog, width: "30%" },
          on: {
            "update:visible": function ($event) {
              _vm.deleteSkillDialog = $event
            },
          },
        },
        [
          _c("span", [_vm._v("Please confirm to delete the SkillMilestone!")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.deleteSkillDialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { disabled: _vm.isDeleteDisabled, type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.deleteSkillData()
                    },
                  },
                },
                [_vm._v("Confirm")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }