// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "", ""]);
// Exports
exports.locals = {
	"menuText": "#222",
	"menuActiveText": "#EF6E23",
	"subMenuActiveText": "#EF6E23",
	"menuBg": "#FFF",
	"menuHover": "#CCC",
	"subMenuBg": "#FFF",
	"subMenuHover": "#CCC",
	"sideBarWidth": "15rem"
};
module.exports = exports;
