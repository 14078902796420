// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/.pnpm/css-loader@3.6.0_webpack@4.46.0/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/app_images/pat.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "[data-v-13877386]:export {\n  menuText: #222;\n  menuActiveText: #EF6E23;\n  subMenuActiveText: #EF6E23;\n  menuBg: #FFF;\n  menuHover: #CCC;\n  subMenuBg: #FFF;\n  subMenuHover: #CCC;\n  sideBarWidth: 15rem;\n}\n.app-wrapper[data-v-13877386] {\n  position: relative;\n  height: 100%;\n  width: 100%;\n}\n.app-wrapper[data-v-13877386]:after {\n  content: \"\";\n  display: table;\n  clear: both;\n}\n.app-wrapper.mobile.openSidebar[data-v-13877386] {\n  position: fixed;\n  top: 0;\n}\n.drawer-bg[data-v-13877386] {\n  background: #000;\n  opacity: 0.3;\n  width: 100%;\n  top: 0;\n  height: 100%;\n  position: absolute;\n  z-index: 999;\n}\n.fixed-header[data-v-13877386] {\n  position: fixed;\n  top: 0;\n  right: 0;\n  z-index: 9;\n  width: calc(100% - 15rem);\n  -webkit-transition: width 0.28s;\n  transition: width 0.28s;\n}\n.hideSidebar .fixed-header[data-v-13877386] {\n  width: calc(100% - 54px);\n}\n.mobile .fixed-header[data-v-13877386] {\n  width: 100%;\n}\n.bgpat[data-v-13877386] {\n  background: #e6e9ee;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: repeat;\n}", ""]);
// Exports
module.exports = exports;
