"use strict";

var _interopRequireWildcard = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.array.filter.js");
require("/usr/src/app/node_modules/.pnpm/core-js@3.6.5/node_modules/core-js/modules/es.array.iterator.js");
require("/usr/src/app/node_modules/.pnpm/core-js@3.6.5/node_modules/core-js/modules/es.promise.js");
require("/usr/src/app/node_modules/.pnpm/core-js@3.6.5/node_modules/core-js/modules/es.object.assign.js");
require("/usr/src/app/node_modules/.pnpm/core-js@3.6.5/node_modules/core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./permission");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _moment = _interopRequireDefault(require("moment"));
// a modern alternative to CSS resets

// global css

// icon
// permission control
// error log

// global filters

_vue.default.prototype.moment = _moment.default;
_vue.default.use(_elementUi.default, {
  locale: _en.default,
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.config.productionTip = false;
_vue.default.filter('formatDate', function (value) {
  if (value) {
    return (0, _moment.default)(String(value)).format('DD/MM/YYYY');
  }
});
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});