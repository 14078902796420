"use strict";

var _interopRequireDefault = require("/usr/src/app/node_modules/.pnpm/@babel+runtime@7.21.5/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _hms_logo = _interopRequireDefault(require("@/assets/app_images/hms_logo.png"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: 'RMS',
      logo: _hms_logo.default
    };
  }
};
exports.default = _default;