var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "8px",
            "text-align": "left",
            "padding-top": "5px",
            "padding-left": "10px",
          },
        },
        [
          _c("el-date-picker", {
            attrs: {
              type: "daterange",
              disabled: _vm.isDisabled,
              "range-separator": "-",
              format: "dd-MM-yyyy",
              "value-format": "yyyy-MM-dd",
              "start-placeholder": "Start date",
              "end-placeholder": "End date",
            },
            on: {
              change: function ($event) {
                return _vm.getFreeResourceList()
              },
            },
            model: {
              value: _vm.dualDate,
              callback: function ($$v) {
                _vm.dualDate = $$v
              },
              expression: "dualDate",
            },
          }),
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              attrs: {
                placeholder: "Select Category",
                disabled: _vm.dropdownDisable,
              },
              on: { change: _vm.getFreeResourceList },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.value, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              attrs: {
                placeholder: "Select sub category",
                disabled: _vm.dropdownDisable,
              },
              on: { change: _vm.getFreeResourceList },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            [
              _c("el-option", {
                attrs: { label: "8-hours free", value: "8-hours free" },
              }),
              _c("el-option", {
                attrs: {
                  label: "Less than 8 hours",
                  value: "less than 8 hours",
                },
              }),
              _c("el-option", { attrs: { label: "Both ", value: "both " } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            "default-sort": { prop: "employeeCode", order: "ascending" },
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
            data: _vm.tableData,
            height: "630",
          },
        },
        [
          this.flag
            ? _c("el-table-column", {
                key: "1",
                attrs: {
                  fixed: "",
                  sortable: "",
                  width: "160px",
                  label: "Department",
                  prop: "name",
                },
              })
            : _vm._e(),
          this.flag
            ? _c("el-table-column", {
                key: "2",
                attrs: {
                  fixed: "",
                  sortable: "",
                  width: "160px",
                  label: "Category",
                  prop: "subName",
                },
              })
            : _vm._e(),
          this.flag == false
            ? _c("el-table-column", {
                key: "3",
                attrs: {
                  fixed: "",
                  sortable: "",
                  width: "160px",
                  label: "Department",
                  prop: "subName",
                },
              })
            : _vm._e(),
          this.flag == false
            ? _c("el-table-column", {
                key: "4",
                attrs: {
                  fixed: "",
                  sortable: "",
                  width: "160px",
                  label: "Category",
                  prop: "name",
                },
              })
            : _vm._e(),
          _vm._l(_vm.Dates, function (item1) {
            return [
              _c("el-table-column", {
                attrs: {
                  align: "center",
                  label: _vm._f("formatDate")(item1),
                  width: "100px;",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (props) {
                        return _vm._l(
                          props.row.freeEmployeeList,
                          function (item2) {
                            return _c("span", [
                              item2.date == item1
                                ? _c("span", [
                                    _vm._v(
                                      " " + _vm._s(item2.employeeCount) + " "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          }
                        )
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }