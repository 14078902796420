var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            "padding-bottom": "8px",
            "text-align": "left",
            "padding-top": "5px",
            "padding-left": "10px",
          },
        },
        [
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              attrs: {
                placeholder: "Select Category",
                disabled: _vm.dropdownDisable,
              },
              on: { change: _vm.getsubCategoryList },
              model: {
                value: _vm.value,
                callback: function ($$v) {
                  _vm.value = $$v
                },
                expression: "value",
              },
            },
            _vm._l(_vm.options, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.value, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "header-search-select",
              attrs: { disabled: _vm.dropdownDisable, filterable: "" },
              on: { change: _vm.getPCRReport },
              model: {
                value: _vm.subtypeId,
                callback: function ($$v) {
                  _vm.subtypeId = $$v
                },
                expression: "subtypeId",
              },
            },
            _vm._l(_vm.subCategory, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "PCRexport",
              staticStyle: { float: "right" },
              attrs: { type: "primary", size: "small" },
              on: {
                click: function ($event) {
                  return _vm.HandleExport()
                },
              },
            },
            [
              _c("i", { staticClass: "el-icon-download" }),
              _c("span", [_vm._v("Export")]),
            ]
          ),
          _c("el-input", {
            staticStyle: { width: "20%", float: "right" },
            attrs: {
              disabled: _vm.isDisabled,
              size: "small",
              placeholder: "Type and press enter to search",
            },
            on: {
              input: function ($event) {
                return _vm.getCount(_vm.search)
              },
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.searchByName()
              },
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            height: "570",
            "default-sort": { prop: "employeeCode", order: "ascending" },
            "element-loading-text": "Loading...",
            "element-loading-spinner": "el-icon-loading",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              sortable: "",
              label: "Employee ID",
              prop: "employeeCode",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "230px",
              sortable: "",
              label: "Employee Name",
              prop: "employeeName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              sortable: "",
              label: "project Name",
              prop: "projectName",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              sortable: "",
              label: "Task Completion",
              prop: "taskCompletion",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "180px",
              align: "center",
              sortable: "",
              label: "Communication",
              prop: "communication",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              sortable: "",
              label: "Meetings",
              prop: "meetings",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "100px",
              align: "center",
              sortable: "",
              label: "Bug",
              prop: "bug",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              align: "center",
              sortable: "",
              label: "Time Sheet",
              prop: "timeSheet",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              align: "center",
              sortable: "",
              label: "Technical",
              prop: "technical",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              sortable: "",
              label: "Process",
              prop: "process",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "120px",
              align: "center",
              sortable: "",
              label: "Profit",
              prop: "profit",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "150px",
              align: "center",
              sortable: "",
              label: "PM Feedback",
              prop: "pmFeedback",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "200px",
              sortable: "",
              label: "Status",
              prop: "status",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              sortable: "",
              align: "center",
              label: "Rating",
              prop: "rating",
            },
          }),
          _c("el-table-column", {
            attrs: {
              width: "130px",
              sortable: "",
              align: "center",
              label: "Total",
              prop: "totalRanking",
            },
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: { total: _vm.total, page: _vm.page, limit: _vm.temp1.limit },
        on: {
          "update:page": function ($event) {
            _vm.page = $event
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.temp1, "limit", $event)
          },
          pagination: function ($event) {
            return _vm.getPCRReport()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }